import apiConfig from '../../../common/config/apiConfig/ApiConfig';
import { STATIC_ASSETS_URL } from '../../../common/constants/AppConstants';
import axios from '../../../common/interceptor/interceptor';
import { CmsDataTypes, CmsRequestPayloadTypes } from '../types/CmsDataTypes';

const COMMON_ENDPOINTS = {
   getCmsDataUrl: `${apiConfig.cmsBaseEndPoint}widgets/v1/web/pages`,
   getCmsDataFromAssetsUrl :  `${STATIC_ASSETS_URL}/json/cms-data.json`
};

/**
 * get homepage data from CMS
 */
export const getCmsDataApi = (data: CmsRequestPayloadTypes): Promise<CmsDataTypes> => {
   return axios({
      method: 'POST',
      url: COMMON_ENDPOINTS.getCmsDataUrl,
      data,
   });
};

/**
 * get CMS data from Assets
 * @returns {Promise<CmsDataTypes>}
 */
export const getCmsDataFromAssetsApi = (): Promise<CmsDataTypes> => {
   return axios({
      method: 'GET',
      url: COMMON_ENDPOINTS.getCmsDataFromAssetsUrl,
   });
};