import React from 'react';
import { Header } from '@airtel-web/legos';
import SkeletonLoading from '../../SkeletonLoading';
import BannerSkeleton from '../banner-skeleton/BannerSkeleton';
import { LANDING_SKELETON_TEST_ID } from './LandingSkeletonConstants';
import { getFromSessionStorage } from '../../../../../../utils/utils';
import { STORAGE_CONSTANTS } from '../../../../../../common/constants/AppConstants';
import './LandingSkeleton.scss';

const LandingSkeleton = () => {
   return (
      <div className="landing-loading-container" data-testid="landing-skeleton">
         {/* {!getFromSessionStorage(STORAGE_CONSTANTS.ANNOUNCEMENT_BAR_SHOWN) && (
            <div className="announcement-bar-skeleton"></div>                          // Commented for Future Use
         )} */}
         <Header
            isUserLoggedIn={false}
            hideAccountIcon
            showMenu={false}
            appName="homepage"
         />
         <BannerSkeleton />
         <div className="home-recharges-skeleton">
            <SkeletonLoading
               skeletonType="heading"
               testId={LANDING_SKELETON_TEST_ID.heading1Container}
            />
            <SkeletonLoading
               skeletonType="chips"
               testId={LANDING_SKELETON_TEST_ID.heading2Container}
            />
            <SkeletonLoading
               skeletonType="heading"
               testId={LANDING_SKELETON_TEST_ID.heading3Container}
            />
            <SkeletonLoading
               skeletonType="heading"
               testId={LANDING_SKELETON_TEST_ID.heading4Container}
            />
         </div>
      </div>
   );
};

export default LandingSkeleton;
