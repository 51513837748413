import React from 'react';
import ReactDOM from 'react-dom/client';
import { initialiseSentry } from '@airtel-lego/sentry';
import './index.scss';
import App from './App';

/**
 * initialise sentry sdk
 */
initialiseSentry({
   dsn: process.env.REACT_APP_SENTRY_DSN,
   release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`,
   environment: process.env.REACT_APP_SENTRY_ENV,
   tracesSampleRate: 0.2,
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
   <React.StrictMode>
      <App />
   </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
