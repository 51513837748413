import { createUUID } from '@airtel-web/legos';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { CONSUMER_NAME_HEADER } from '../constants/AppConstants';

const axiosInstance = axios.create() as any;

axiosInstance.interceptors.request.use(
   async (request: any) => {
      request.headers['Content-Type'] = 'application/json';
      request.headers['x-at-application'] = 'home_recast';
      request.headers['x-at-client'] = 'WEB';
      request.headers['x-at-reqid'] = createUUID();
      request.headers['X-Consumer-Name'] = CONSUMER_NAME_HEADER;
      return request;
   },
   (error: AxiosError) => {
      return Promise.reject(error);
   }
);

axiosInstance.interceptors.response.use(
   (res: AxiosResponse) => {
      return res?.data;
   },
   (error: AxiosError) => {
      return handleResponseError(error);
   }
);

/**
 * handle response error
 * @param error
 */
const handleResponseError = (error: AxiosError): Promise<any> | void => {
   return Promise.reject(error?.response || error);
};

export default axiosInstance;
