import React, { FC } from 'react';
import { Header } from '@airtel-web/legos';
import { ANALYTICS_CONSTANTS } from '../../../../common/constants/AnalyticsConstants';
import { isUserLoggedIn } from '../../../../utils/utils';

const HeaderSection: FC = () => {
   const data = {
      journey: ANALYTICS_CONSTANTS.webHomepage,
      eventCategory: ANALYTICS_CONSTANTS.webHomepage,
      prop0: ANALYTICS_CONSTANTS.homepage,
   };

   return (
      <Header
         className="header-section"
         isUserLoggedIn={isUserLoggedIn()}
         trackingData={data}
         appName="homepage"
      />
   );
};

export default HeaderSection;
