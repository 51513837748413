import React, { FC } from 'react';
import {
   GridColumn,
   GridRow,
   Image,
   Typography,
   useGetMediaBreakpoint,
} from '@airtel-web/legos';
import { navigateTo } from '../../../../utils/utils';
import { B2B_Data, B2C_Data } from './FallbackPageData';
import colors from '@airtel-web/legos/lib/styles/variables/colors.module.scss';
import './FallbackPage.scss';

export const FallbackPage: FC = () => {
   const mediaBreakpoint = useGetMediaBreakpoint();
   const isMobile = mediaBreakpoint === 'sm' || mediaBreakpoint === 'xs';
   const isMdDevice = mediaBreakpoint === 'md';

   const renderTiles = (dataArray: any[]) => {
      return (
         <GridColumn alignSelf="center" noGutter={isMobile || isMdDevice}>
               <GridRow noMargin className="links-container">
                  {dataArray.map((data, index) => (
                     <GridColumn
                        key={'b2c-slide-' + index}
                        className="links-blocks"
                        breakpoints={['col-lg-2', 'col-md-4', 'col-sm-4']}
                        noGutter={isMobile || isMdDevice}
                     >
                        <div
                           className="link-card"
                           onClick={() => {
                              navigateTo(data.ctaLink);
                           }}
                        >
                           <Image
                              alt={data.title}
                              src={data.imageLink}
                              height={isMobile ? 36 : 48}
                           />
                           <Typography
                              type={
                                 isMobile ? 'body-single-line-xxs' : 'body-single-line-xs'
                              }
                              color={colors.black600}
                           >
                              {data.title}
                           </Typography>
                        </div>
                     </GridColumn>
                  ))}
               </GridRow>
            </GridColumn>
      )
   }

   return (
      <div className="fallback-page-container">
         <GridRow direction="row-as-column">
            <GridColumn alignSelf="center" className="heading">
               <Typography type="h3" color={colors.black900}>
                  Oops!
               </Typography>
            </GridColumn>
            <GridColumn alignSelf="center">
               <Image
                  alt="404 image"
                  src="https://assets.airtel.in/static-assets/selfcare/images/404/group-158@2x.png"
                  width="361"
                  height="198"
                  className="image"
               />
            </GridColumn>
            <GridColumn alignSelf="center" className="subheading">
               <Typography type="h6" color={colors.black700}>
                  Page you are looking for could not be found.
               </Typography>
            </GridColumn>
            <GridColumn alignSelf="center" className="subheading-2">
               <Typography type="body-para-lg">
                  Meanwhile you can check other services we offer.
               </Typography>
            </GridColumn>
            {
               renderTiles(B2C_Data)
            }
            <GridColumn alignSelf="center" className="align-center">
               <Typography type="body-para-sm-bold" color={colors.black900}>
                  Products for all your business needs
               </Typography>
            </GridColumn>
            {
               renderTiles(B2B_Data)
            }
         </GridRow>
      </div>
   );
};
