import React, { FC, useEffect, useState } from 'react';
import { Toast, Button } from '@airtel-web/legos';
import { TEST_PATTERNS } from '../../../../common/constants/AppConstants';
import './CookiesSection.scss';

const CookiesSection: FC = () => {
   const [showCookiesContainer, setShowCookiesContainer] = useState(false);

   useEffect(() => {
      const isMatch = document.cookie.match(new RegExp(TEST_PATTERNS.cookies));
      if (!isMatch) setShowCookiesContainer(true); // show cookies
   }, []);

   const onDismissHandler = () => {
      storeCookie();
      setShowCookiesContainer(false);
   };

   const storeCookie = () => {
      const key = 'cookiePermission';
      const value = 'true';
      const date = new Date();
      date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000); //milliseconds in 1 year
      const expires = 'expires=' + date.toUTCString();
      document.cookie = `${key}=${value};${expires};path=/`;
   };

   return (
      <Toast
         className="cookies-container"
         width={559}
         show={showCookiesContainer}
         title="We use cookies to ensure you get the best experience"
         cta={
            <Button buttonType="alternate-light" onClick={onDismissHandler}>
               Got it
            </Button>
         }
         position="bottom-center"
      />
   );
};

export default CookiesSection;
