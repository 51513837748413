import React, { FC, useEffect } from 'react';
import {
   Badge,
   Card,
   GridColumn,
   GridRow,
   IconButton,
   Typography,
   RightArrowIcon,
   LeftArrowIcon,
   useGetMediaBreakpoint,
   useOrientation,
} from '@airtel-web/legos';
import clsx from 'clsx';
import {
   ConversationalCardTypes,
   ConversationalWidgetDataButton,
} from './types/ConversationalCardTypes';
import { ConversationCardConstants } from './ConversationCardConstants';
import { fireAnalytics } from '../../../../service/HomePageAnalyticsService';
import { ANALYTICS_CONSTANTS } from '../../../../../../common/constants/AnalyticsConstants';
import colors from '@airtel-web/legos/lib/styles/variables/colors.module.scss';
import './ConversationalCard.scss';

const ConversationalCard: FC<ConversationalCardTypes> = (props) => {
   const {
      conversationalData,
      conversationalDataForm,
      showGradient,
      moveToPrevious,
      onConversationalOptionClick,
      moveToResult,
   } = props;

   const orientation = useOrientation();
   const mediaBreakpoint = useGetMediaBreakpoint();
   const isMobile = mediaBreakpoint === 'xs' || mediaBreakpoint === 'sm';
   const isMdDevice = mediaBreakpoint === 'md';
   const isLgDevice = mediaBreakpoint === 'lg';
   const disabledUsageScreen =
      conversationalData?.conversationType === ConversationCardConstants.USAGE &&
      showGradient;

   useEffect(() => {
      conversationalData?.buttons.forEach((button, index) => {
         const buttonEvents = {
            section: ANALYTICS_CONSTANTS.whatSpeedDoINeed,
            eventAction: ANALYTICS_CONSTANTS.impression,
            eventLabel: button?.text?.toLowerCase(),
            customLabel: ANALYTICS_CONSTANTS.button,
            isInteractive: '0',
            horizontalPosition: index.toString(),
            verticalPosition: '9',
         };
         fireAnalytics(
            buttonEvents,
            `${conversationalData.conversationType}_${button.id}`
         );
      });
   }, []);

   const isSelected = (buttonData: ConversationalWidgetDataButton) => {
      if (
         conversationalData?.conversationType === ConversationCardConstants.NO_OF_DEVICES
      ) {
         return conversationalDataForm.noOfDevices === buttonData.value;
      } else if (
         conversationalData?.conversationType === ConversationCardConstants.USAGE
      ) {
         return conversationalDataForm.usage.includes(buttonData);
      }
   };

   return (
      <Card
         className={clsx(
            isMdDevice && orientation === 'landscape'
               ? 'conversation-card low-padding'
               : 'conversation-card',
            disabledUsageScreen && 'conversation-card-hidden'
         )}
      >
         <GridRow
            noMargin
            fullWidth
            direction="row-as-column"
            className="conversation-card-inner"
         >
            <GridColumn alignSelf="center" noGutter>
               <Typography type="title-para-md" color={colors.TRed500} className="label">
                  {conversationalData?.label}
               </Typography>
            </GridColumn>
            <GridColumn noGutter>
               <GridRow direction="row-as-column" fullWidth noMargin>
                  <GridColumn alignSelf="center" noGutter>
                     <Typography
                        type="body-para-lg-bold"
                        color={colors.black900}
                        className="heading"
                     >
                        {conversationalData?.heading}
                     </Typography>
                  </GridColumn>
                  <GridColumn alignSelf="center" noGutter>
                     <Typography
                        type="body-para-sm"
                        color={colors.black900}
                        className="subHeading"
                     >
                        {conversationalData?.subHeading}
                     </Typography>
                  </GridColumn>
               </GridRow>
            </GridColumn>
            <GridColumn noGutter>
               <GridRow
                  noMargin={isMdDevice || isLgDevice}
                  fullWidth
                  direction="row-as-column"
                  className="button-container"
               >
                  {conversationalData?.buttons.map((buttonData) => (
                     <GridColumn
                        noGutter
                        key={buttonData.id}
                        className="conversational-widget-badge"
                     >
                        <Badge
                           size="lg"
                           id={conversationalData.conversationType + '_' + buttonData.id}
                           data-testid={
                              conversationalData.conversationType + '_' + buttonData.id
                           }
                           data-conversation-type={
                              conversationalData.conversationType +
                              '-' +
                              isSelected(buttonData)
                           }
                           type={
                              conversationalData.conversationType === 'usage'
                                 ? 'multi'
                                 : 'single'
                           }
                           isSelected={isSelected(buttonData)}
                           onClick={() => {
                              onConversationalOptionClick(
                                 conversationalData.conversationType,
                                 buttonData
                              );
                           }}
                           disabled={disabledUsageScreen}
                        >
                           {buttonData.text}
                        </Badge>
                     </GridColumn>
                  ))}
               </GridRow>
            </GridColumn>
            {isMobile && (
               <div className="conversational-card-controls">
                  {conversationalData?.conversationType !==
                     ConversationCardConstants.NO_OF_DEVICES && (
                     <IconButton
                        className="previous-slide-button"
                        size="xs"
                        id="previousSlide"
                        type="primary"
                        icon={LeftArrowIcon}
                        onClick={moveToPrevious}
                     />
                  )}
                  {conversationalData?.conversationType ===
                     ConversationCardConstants.USAGE && (
                     <IconButton
                        className="next-slide-button"
                        size="xs"
                        id="nextSlide"
                        type="primary"
                        icon={RightArrowIcon}
                        onClick={moveToResult}
                        isDisabled={conversationalDataForm.usage.length === 0}
                     />
                  )}
               </div>
            )}
         </GridRow>
         {disabledUsageScreen && <div className="gradient-div"></div>}
      </Card>
   );
};

export default ConversationalCard;
