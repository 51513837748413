import React, { FC, useEffect, useRef, useState } from 'react';
import {
   useGetMediaBreakpoint,
   GridColumn,
   Typography,
   GridRow,
   Button,
   Badge,
   Input,
} from '@airtel-web/legos';
import { TEST_PATTERNS } from '../../../../common/constants/AppConstants';
import { LOB_SECTION_DETAILS, BADGE_LIST, LOB } from './RechargeSectionConstants';
import { redirectToLobPage } from './service/RechargeSectionService';
import { fireAnalytics } from '../../service/HomePageAnalyticsService';
import { ANALYTICS_CONSTANTS } from '../../../../common/constants/AnalyticsConstants';
import colors from '@airtel-web/legos/lib/styles/variables/colors.module.scss';
import './RechargeSection.scss';

const RechargeSection: FC = () => {
   const [selectedLob, setSelectedLob] = useState<string>(LOB.prepaid);
   const [rechargeInput, setRechargeInput] = useState<string>('');
   const [errMsg, setErrMsg] = useState<string>('');
   const mediaBreakpoint = useGetMediaBreakpoint();
   const isMobile = mediaBreakpoint === 'xs' || mediaBreakpoint === 'sm';
   const isSmallDevice = mediaBreakpoint === 'xs';
   const inputRef = useRef<HTMLInputElement>(null);

   const inputEvents = {
      section: ANALYTICS_CONSTANTS.rechargePayBill,
      eventAction: ANALYTICS_CONSTANTS.impression,
      eventLabel: ANALYTICS_CONSTANTS.enterNumber,
      eventValue: rechargeInput,
      customLabel: ANALYTICS_CONSTANTS.textbox,
      customValue: '',
      isInteractive: '0',
      horizontalPosition: '5',
      verticalPosition: '3',
   };

   const ctaEvents = {
      section: ANALYTICS_CONSTANTS.rechargePayBill,
      eventAction: ANALYTICS_CONSTANTS.impression,
      eventLabel: ANALYTICS_CONSTANTS.recharge,
      eventValue: '',
      customLabel: ANALYTICS_CONSTANTS.button,
      customValue: '',
      isInteractive: '0',
      horizontalPosition: '6',
      verticalPosition: '3',
   };

   useEffect(() => {
      BADGE_LIST.forEach((badge, index) => {
         const badgeEvents = {
            section: ANALYTICS_CONSTANTS.rechargePayBill,
            eventAction: ANALYTICS_CONSTANTS.impression,
            eventLabel: ANALYTICS_CONSTANTS.filter,
            eventValue: badge.displayName?.toLowerCase(),
            customLabel: ANALYTICS_CONSTANTS.button,
            customValue: '',
            isInteractive: '0',
            horizontalPosition: index.toString(),
            verticalPosition: '3',
         };
         fireAnalytics(badgeEvents, `badge-item-${index}`);
      });
      fireAnalytics(inputEvents, 'rechargeInput');
      fireAnalytics(ctaEvents, 'rechargeBtn');
   }, []);

   /**
    * lob badge click handling
    * @param {string} lob
    */
   const onBadgeClickHandler = (lob: string) => {
      setErrMsg('');
      setSelectedLob(lob);
      setRechargeInput('');
      fireAnalytics({
         section: ANALYTICS_CONSTANTS.rechargePayBill,
         eventAction: ANALYTICS_CONSTANTS.click,
         eventLabel: ANALYTICS_CONSTANTS.filter,
         eventValue: lob?.toLowerCase(),
         customLabel: ANALYTICS_CONSTANTS.button,
         isInteractive: '1',
         horizontalPosition: '0',
         verticalPosition: '3',
      });
      if (inputRef.current) inputRef.current.focus();
   };

   /**
    * recharge number change handling
    * @param {React.ChangeEvent<HTMLInputElement>} event
    */
   const onInputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      unknownCharacterHandler(value);
   };

   /**
    * handles keyboard enter event
    * @param {React.KeyboardEvent<HTMLInputElement>} event
    */
   const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
         onSubmitHandler();
      }
   };

   /**
    * to remove the unwanted characters from recharge input
    * @param {string} value
    */
   const unknownCharacterHandler = (value: string) => {
      const regEx = LOB_SECTION_DETAILS[selectedLob].regEx;
      setRechargeInput(value.replace(regEx, ''));
      errorMsgHandler(value);
   };

   /**
    * error handling for recharge number
    * @param {string} value
    * @returns {string}
    */
   const errorMsgHandler = (value: string) => {
      if (inputRef.current) inputRef.current.focus();
      setErrMsg('');
      const { maxLength, minLength, errorMsg } = LOB_SECTION_DETAILS[selectedLob];
      if (!value) return { msg: errorMsg, valueLength: value.length };

      if (value.length < minLength) return { msg: errorMsg, valueLength: value.length };

      let _errMsg = '';

      let isValid = null;

      if (selectedLob === LOB.dth) {
         isValid =
            value.match(TEST_PATTERNS.mobileNumber) ||
            value.match(TEST_PATTERNS.dthAndBroadband);
      } else if (
         selectedLob === LOB.prepaid ||
         selectedLob === LOB.postpaid ||
         selectedLob === LOB.airtelBlack
      ) {
         isValid = value.match(TEST_PATTERNS.mobileNumber);
      }

      if (selectedLob !== LOB.dsl && value.length === maxLength && isValid) {
         fireAnalytics({
            ...inputEvents,
            eventAction: ANALYTICS_CONSTANTS.click,
            eventValue: value,
            customValue: '',
            isInteractive: '1',
         });
         redirectToLobPage(selectedLob, value);
      } else if (selectedLob === LOB.dsl) {
         return { msg: _errMsg, valueLength: value.length };
      } else {
         _errMsg = errorMsg;
         setErrMsg(_errMsg);
      }

      return { msg: _errMsg, valueLength: value.length };
   };

   /**
    * on submitting the recharge number
    */
   const onSubmitHandler = () => {
      const { minLength, maxLength, errorMsg } = LOB_SECTION_DETAILS[selectedLob];
      const error = errorMsgHandler(rechargeInput);
      fireAnalytics({
         ...ctaEvents,
         eventAction: ANALYTICS_CONSTANTS.click,
         customValue: '',
         isInteractive: '1',
      });
      if (
         !error.msg &&
         error.valueLength >= minLength &&
         error.valueLength <= maxLength
      ) {
         redirectToLobPage(selectedLob, rechargeInput);
      } else setErrMsg(errorMsg);
   };
   const typographyType = isSmallDevice ? 'body-single-line-lg-bold' : 'h4-bold';
   return (
      <div className="recharge-section-container">
         <GridRow
            noMargin
            fullWidth
            justifyContent="center"
            fitContent={false}
            direction="row-as-column"
         >
            <GridColumn noGutter className="recharge-heading" alignSelf="center">
               <Typography type={typographyType} color={colors.black900}>
                  Recharge or pay bills
               </Typography>
            </GridColumn>

            <div className="badge-container">
               {!!BADGE_LIST.length &&
                  BADGE_LIST.map((item, index) => {
                     const { lob, displayName, prefixFilledIcon } = item;
                     return (
                        <Badge
                           id={`badge-item-${index}`}
                           className={`badge-item-${index}`}
                           type="single"
                           withIcon
                           isSelected={lob === selectedLob}
                           key={lob}
                           prefixIcon={prefixFilledIcon}
                           onClick={() => onBadgeClickHandler(lob)}
                        >
                           {displayName}
                        </Badge>
                     );
                  })}
            </div>
            <GridRow noMargin className="recharge-container">
               <GridColumn
                  alignSelf="center"
                  noGutter
                  className="recharge-input-container"
               >
                  <Input
                     id="rechargeInput"
                     className="recharge-input"
                     name="rechargeInput"
                     label=""
                     type={LOB_SECTION_DETAILS[selectedLob].inputType}
                     maxLength={LOB_SECTION_DETAILS[selectedLob].maxLength}
                     placeholder={LOB_SECTION_DETAILS[selectedLob].placeholder}
                     value={rechargeInput}
                     onChange={onInputChangeHandler}
                     hintText={errMsg}
                     state={errMsg ? 'error' : 'none'}
                     flexibleWidth
                     ref={inputRef}
                     onKeyDown={handleKeyDown}
                     appendText="RECHARGE"
                     showAppend={isSmallDevice}
                     appendOnClickHandler={onSubmitHandler}
                  />
               </GridColumn>
            </GridRow>
            <GridColumn noGutter alignSelf="center" className="recharge-btn-container">
               {!isSmallDevice && (
                  <Button
                     id="rechargeBtn"
                     className="recharge-btn"
                     name="rechargeBtn"
                     buttonType="primary"
                     version="variable-width"
                     onClick={onSubmitHandler}
                     size="md"
                  >
                     {LOB_SECTION_DETAILS[selectedLob].btnLabel}
                  </Button>
               )}
            </GridColumn>
         </GridRow>
      </div>
   );
};

export default RechargeSection;
