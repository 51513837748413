import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import {
    ApiErrorArgTypes,
    ContextValuesArgTypes,
    InitSentryArgTypes,
} from './types/SentryTypes';
import { isDevEnv } from './utils';

export const ErrorBoundary = Sentry.ErrorBoundary;

/**
 * base function for sentry logging in case of error
 * @param {string} errorMsg - Custom error string
 * @param {object} errorException - captured error/ exception
 * @param {ContextValuesArgTypes} contextValues - object with context information for Sentry.captureException method
 * @param {ApiErrorArgTypes} apiErrorInfo - api error info passed by consumer
 */
export const fireLogWithSentryBase = (
    errorMsg: string,
    errorException: any = {},
    contextValues: ContextValuesArgTypes = {},
    apiErrorKeys: ApiErrorArgTypes = {},
) => {
    if (typeof Sentry !== 'undefined') {
        let exception = new Error(errorMsg);
        const [fileName = '', methodName = '', errorInfo = ''] = errorMsg.split('-->');
        let { tags: additionalTags = {} } = contextValues;

        // getting high events of local and session storage issue, disabling temporarily
        const isStorageIssue =  errorInfo.toLowerCase().includes('storage') || errorException?.message?.toLowerCase().includes('storage');

        if (isStorageIssue) {
            return;
        }

        let tags: { [key: string]: any } = {
            isApiError: false,
            'errorSource.fileName': fileName,
            'errorSource.methodName': methodName,
            'errorSource.info': errorInfo,
            ...additionalTags,
        };

        if (errorException) {
            exception = errorException;
            // if errorException is non empty object, it may be api error object
            if (Object.keys(errorException).length) {
                const { status, data } = errorException;
                // api error
                if (status) {
                    const { responseCode = '', errorMsg = '' } = data || {};
                    // since keys from api response differs for different projects, consumer can provide the apiErrorKeys for errorCode, errorMessage
                    const errorCode = data[apiErrorKeys.errorCode] || responseCode,
                        errorMessage = data[apiErrorKeys.errorMessage] || errorMsg;
                    tags.isApiError = true;
                    tags['apiError.responseCode'] = status;
                    tags['apiError.errorCode'] = errorCode;
                    exception = new Error(
                        `${methodName} -> http ${status} error: ${errorCode}: ${errorMessage}`,
                    );
                }

            } else if (typeof errorException === 'string') {
                exception = new Error(errorException);
            }
        }

        Sentry.withScope(() => {
            Sentry.captureException(exception, {
                ...contextValues,
                tags,
                // errorException in extra
                extra: {
                    ...contextValues.extra,
                    jsonErrorException: JSON.stringify(errorException),
                },
            });
        });
    }
};
/**
 * initialise sentry logging
 * @param {InitSentryArgTypes} initSentryObj - sentry init object
 */
export const initialiseSentry = (initSentryObj: InitSentryArgTypes): void => {
    const { dsn, release, environment, tracesSampleRate, isDebugMode, ...restOptions } =
        initSentryObj;

    Sentry.init({
        dsn,
        release,
        environment,
        tracesSampleRate,
        beforeSend(event: any) {
            // Only send event if ENV is not DEV (=localhost)
            if (!isDevEnv() || isDebugMode) {
                return event;
            }
        },
        integrations: [new BrowserTracing()],
        ...restOptions,
    });
};
