import React, { FC, useEffect, useRef, useState } from 'react';
import {
   Typography,
   GridRow,
   Button,
   GridColumn,
   ChevronRightIcon,
   useGetMediaBreakpoint,
   Slider,
   SliderRefTypes,
   SliderOnChangeTypes,
   IconButton,
   LeftArrowIcon,
   RightArrowIcon,
} from '@airtel-web/legos';
import B2BSlide from './view/b2b-slide/B2BSlide';
import { B2BSectionPropTypes, WidgetContainersType } from './types/B2BSectionPropTypes';
import { navigateTo } from '../../../../utils/utils';
import { fireAnalytics } from '../../service/HomePageAnalyticsService';
import { ANALYTICS_CONSTANTS } from '../../../../common/constants/AnalyticsConstants';
import { B2B_SECTION_TEST_ID, B2B_SLIDER_BREAKPOINTS } from './B2BConstants';
import colors from '@airtel-web/legos/lib/styles/variables/colors.module.scss';
import './B2BSection.scss';

const B2BSection: FC<B2BSectionPropTypes> = (props) => {
   const { sectionCmsData } = props;
   const { dataAttributes: sectionDataAttributes = {} } = sectionCmsData;
   const slides = sectionCmsData?.widgetContainers;
   const { title, content, cta, ctaLink } = sectionDataAttributes;
   const mediaBreakpoint = useGetMediaBreakpoint();
   const [currSlideIndex, setCurrSlideIndex] = useState(0);
   const sliderRef = useRef<SliderRefTypes>(null);
   const isMobile = mediaBreakpoint === 'xs' || mediaBreakpoint === 'sm';
   const isMdDevice = mediaBreakpoint === 'md';
   const isSmallDevice = mediaBreakpoint === 'xs';
   const showSliderButtons = isMobile || isMdDevice || slides.length > 4;

   const exploreEvents = {
      section: ANALYTICS_CONSTANTS.forYourBusiness,
      eventAction: ANALYTICS_CONSTANTS.impression,
      eventLabel: cta?.toLowerCase(),
      eventValue: '',
      customLabel: ANALYTICS_CONSTANTS.button,
      isInteractive: '0',
      horizontalPosition: '0',
      verticalPosition: '8',
   };

   useEffect(() => {
      fireAnalytics(exploreEvents, 'exploreButton');
   }, []);

   let dataListItem: any = [];
   if (slides?.length > 0) {
      dataListItem = slides.map((item: WidgetContainersType, index: number) => {
         return {
            children: (
               <B2BSlide key={index} attributes={item.dataAttributes} index={index} />
            ),
         };
      });
   }

   const onExploreClick = () => {
      fireAnalytics({
         ...exploreEvents,
         eventAction: ANALYTICS_CONSTANTS.click,
         customValue: '',
         isInteractive: '1',
      });
      navigateTo(ctaLink);
   };

   const handleBannerNext = () => {
      if (sliderRef.current && sliderRef.current.nextSlide) sliderRef.current.nextSlide();
   };

   const handleBannerPrev = () => {
      if (sliderRef.current && sliderRef.current.prevSlide) sliderRef.current.prevSlide();
   };

   const handleSlideChange = (params: SliderOnChangeTypes) => {
      const { currIndex } = params;
      setCurrSlideIndex(currIndex);
   };

   const pageSlider = isMdDevice
      ? `${Math.ceil((currSlideIndex + 3) / 3)}/${Math.ceil(dataListItem.length / 3)}`
      : `${currSlideIndex + 1}/${dataListItem.length}`;

   let autoPlayDelay = 5000;
   if (sectionDataAttributes?.autoPlayDelay >= 5000) {
      autoPlayDelay = sectionDataAttributes.autoPlayDelay;
   }

   const autoPlay = sectionDataAttributes?.autoPlay
      ? sectionDataAttributes.autoPlay
      : false;

   const slideTransitionSpeed = sectionDataAttributes?.slideTransitionSpeed
      ? sectionDataAttributes.slideTransitionSpeed
      : 1000;

   return (
      <div className="b2b-section-container icon-button-black800">
         <GridRow
            justifyContent="center"
            direction="row-as-column"
            className="b2b-section"
            noMargin={isMdDevice || isMobile}
         >
            <GridColumn noGutter className="b2b-main-title-container">
               <Typography
                  type={isSmallDevice ? 'body-single-line-lg-bold' : 'h4-bold'}
                  className="b2b-main-title"
                  color={colors.black900}
               >
                  {title || 'For your business'}
               </Typography>
            </GridColumn>

            <GridColumn noGutter className="b2b-sub-title-container">
               <Typography
                  type={!isMobile ? 'body-para-md' : 'body-para-sm'}
                  className="b2b-sub-title"
                  color={colors.black700}
               >
                  {content || 'Solutions for medium to large scale enterprises'}
               </Typography>
            </GridColumn>

            {cta && (
               <GridColumn noGutter className="b2b-cta">
                  <Button
                     id="exploreButton"
                     className="explore-btn"
                     buttonType="tertiary"
                     icon={ChevronRightIcon}
                     iconPosition="right"
                     showIcon={true}
                     version="variable-width"
                     onClick={onExploreClick}
                     size="sm"
                  >
                     {cta}
                  </Button>
               </GridColumn>
            )}

            <GridColumn noGutter={isMdDevice || isMobile}>
               {dataListItem.length > 0 && (
                  <Slider
                     className="slider"
                     data-testid={B2B_SECTION_TEST_ID.slider}
                     ref={sliderRef}
                     autoPlay={autoPlay}
                     autoPlayDelay={autoPlayDelay}
                     breakpoints={B2B_SLIDER_BREAKPOINTS}
                     data={dataListItem}
                     distanceToSwipeSlide={50}
                     swipeGesture={isMobile}
                     loop
                     slideTransitionSpeed={slideTransitionSpeed}
                     onSlideChangeHandler={handleSlideChange}
                  />
               )}
            </GridColumn>

            {showSliderButtons && (
               <GridColumn className="controls-column">
                  <GridRow className="slider-control-btn-section">
                     <IconButton
                        data-testid={B2B_SECTION_TEST_ID.prevSlideButton}
                        id="previousSlide"
                        className="prev-btn"
                        type="primary"
                        isDisabled={currSlideIndex === 0}
                        size="xs"
                        icon={LeftArrowIcon}
                        onClick={handleBannerPrev}
                     />
                     <Typography
                        type="body-single-line-xs"
                        color={colors.black600}
                        data-testid={B2B_SECTION_TEST_ID.currentBannerDiv}
                     >
                        {pageSlider}
                     </Typography>
                     <IconButton
                        data-testid={B2B_SECTION_TEST_ID.nextSlideButton}
                        id="nextSlide"
                        className="next-btn"
                        type="primary"
                        size="xs"
                        icon={RightArrowIcon}
                        onClick={handleBannerNext}
                     />
                  </GridRow>
               </GridColumn>
            )}
         </GridRow>
      </div>
   );
};

export default B2BSection;
