import { ConversationalDataFormType } from './types/ConversationalWidgetTypes';
import { ConversationalWidgetDataType } from './view/conversational-card/types/ConversationalCardTypes';

export const sliderBreakpoints = {
   sm: {
      gutter: 0,
      seekGap: 0,
      slidesToScroll: 1,
      slidesToShow: 1,
   },
   md: {
      gutter: 24,
      seekGap: 0,
      slidesToScroll: 1,
      slidesToShow: 3,
   },
   lg: {
      gutter: 32,
      seekGap: 0,
      slidesToScroll: 1,
      slidesToShow: 3,
   },
};

export const calculateSpeedAndPrice = (
   conversationalDataForm: ConversationalDataFormType
) => {
   const speed = conversationalDataForm.usage?.reduce((acc, currUsage) => {
      return acc + currUsage.value[conversationalDataForm.noOfDevices];
   }, 0);
   let recommendedSpeed = '',
      price = 0;
   if (speed < 40) {
      recommendedSpeed = '40 Mbps';
      price = 499;
   } else if (speed >= 40 && speed < 100) {
      recommendedSpeed = '100 Mbps';
      price = 799;
   } else if (speed >= 100 && speed < 200) {
      recommendedSpeed = '200 Mbps';
      price = 999;
   } else if (speed >= 200 && speed < 300) {
      recommendedSpeed = '300 Mbps';
      price = 1498;
   } else {
      recommendedSpeed = '1 Gbps';
      price = 3999;
   }
   return { recommendedSpeed, price };
};

export const updateConversationalDataStata = (
   conversationalDataState: ConversationalWidgetDataType[],
   recommendedSpeed: string,
   price: number
) => {
   return conversationalDataState.map((conversationalData) => {
      if (conversationalData.conversationType === 'result') {
         return {
            ...conversationalData,
            heading: recommendedSpeed,
            subHeading: `Rs. ${price} per month`,
            buttons: conversationalData.buttons.map((b, i) => {
               if (i === 0) {
                  return {
                     ...b,
                     link: 'https://www.airtel.in/broadband/?lf_open=' + price,
                  };
               }
               return b;
            }),
         };
      }
      return conversationalData;
   });
};
