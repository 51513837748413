import React, { FC } from 'react';

export const SliderDot: FC<{ fill: string }> = (props) => {
   return (
      <svg
         width="8"
         height="8"
         viewBox="0 0 8 8"
         fill={props.fill}
         xmlns="http://www.w3.org/2000/svg"
      >
         <circle cx="4" cy="4" r="3.5" stroke="#000000" />
      </svg>
   );
};
