import React, { useEffect } from 'react';
import { IconButton, DynamicSvg, useGetMediaBreakpoint } from '@airtel-web/legos';
import { navigateTo } from '../../../../../../utils/utils';
import { BuyNewConnectionPropTypes } from './types/BuyNewConnectionPropTypes';
import { ANALYTICS_CONSTANTS } from '../../../../../../common/constants/AnalyticsConstants';
import { fireAnalytics } from '../../../../service/HomePageAnalyticsService';
import './BuyNewConnection.scss';

const BuyNewConnection = (props: BuyNewConnectionPropTypes) => {
   const { linksCmsData } = props;
   const mediaBreakpoint = useGetMediaBreakpoint();
   const isMobile = mediaBreakpoint === 'xs' || mediaBreakpoint === 'sm';

   useEffect(() => {
      linksCmsData.widgetContainers?.forEach((widget, index) => {
         const ctaEvents = {
            section: ANALYTICS_CONSTANTS.buyNewConnection,
            eventAction: ANALYTICS_CONSTANTS.impression,
            eventLabel: widget.dataAttributes?.labelText?.toLowerCase(),
            eventValue: '',
            customLabel: ANALYTICS_CONSTANTS.button,
            isInteractive: '0',
            horizontalPosition: index.toString(),
            verticalPosition: '5',
         };
         fireAnalytics(ctaEvents, `buy-connection-button-${index}`);
      });
   }, []);

   const onCtaClick = (label: string, link: string, index: number) => {
      const ctaEvents = {
         section: ANALYTICS_CONSTANTS.buyNewConnection,
         eventAction: ANALYTICS_CONSTANTS.click,
         eventLabel: label?.toLowerCase(),
         eventValue: '',
         customLabel: ANALYTICS_CONSTANTS.button,
         isInteractive: '1',
         horizontalPosition: index.toString(),
         verticalPosition: '5',
      };
      fireAnalytics(ctaEvents);
      navigateTo(link);
   };

   return (
      <div className="switch-to-airtel-links-container">
         {linksCmsData.widgetContainers?.map((widget, index) => (
            <IconButton
               key={index}
               size={isMobile ? 'md' : 'lg'}
               className="switch-to-airtel-link"
               id={`buy-connection-button-${index}`}
               icon={(_color, ...svgProps) => (
                  /* @ts-ignore */
                  <DynamicSvg
                     color="black"
                     {...svgProps}
                     name={widget.dataAttributes.icon}
                  />
               )}
               onClick={() => {
                  onCtaClick(
                     widget.dataAttributes.labelText,
                     widget.dataAttributes.ctaLink,
                     index
                  );
               }}
               label={widget.dataAttributes.labelText}
               type="secondary"
               textPosition="bottom"
            />
         ))}
      </div>
   );
};

export default BuyNewConnection;
