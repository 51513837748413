import React, { FC } from 'react';
import { checkMobile, getFromSessionStorage, hasData } from '../../../../utils/utils';
import { SECTIONS_ENUM } from '../../HomePageConstants';
import { getSectionCmsData } from '../../service/HomePageService';
import SectionPropTypes from './types/SectionPropTypes';
import { CmsDataSectionTypes } from '../../types/CmsDataTypes';
import AnnouncementBar from '../announcement-bar';
import HeaderSection from '../header-section';
import OfferSection from '../offer-section';
import RechargeSection from '../recharge-section';
import HeroBanner from '../hero-banner/HeroBanner';
import B2BSection from '../b2b-section/B2BSection';
import ConversationalWidget from '../conversational-widget/ConversationalWidget';
import Subscriptions from '../subscriptions';
import SwitchToAirtel from '../switch-to-airtel';
import Recommendations from '../recommendations';
import { STORAGE_CONSTANTS } from '../../../../common/constants/AppConstants';

const Section: FC<SectionPropTypes> = (props) => {
   const isMobile = checkMobile();
   const { sectionDetails, cmsSectionList } = props;
   const { sectionName, poweredBy } = sectionDetails;

   const renderSection = () => {
      const sectionCmsData: CmsDataSectionTypes | null =
         poweredBy === 'CMS' ? getSectionCmsData(sectionName, cmsSectionList) : null;

      const _sections = {
         // [SECTIONS_ENUM.ANNOUNCEMENT_BAR_SECTION]:
         //    hasData(sectionCmsData) &&
         //    !getFromSessionStorage(STORAGE_CONSTANTS.ANNOUNCEMENT_BAR_SHOWN) ? (             // Commented For Future Use
         //       <AnnouncementBar sectionCmsData={sectionCmsData as CmsDataSectionTypes} />
         //    ) : null,
         [SECTIONS_ENUM.HEADER_SECTION]: <HeaderSection />,
         [SECTIONS_ENUM.RECHARGE_SECTION]: <RechargeSection />,
         [SECTIONS_ENUM.OFFER_SECTION]: hasData(sectionCmsData) ? (
            <OfferSection sectionCmsData={sectionCmsData as CmsDataSectionTypes} />
         ) : null,
         [SECTIONS_ENUM.HERO_BANNER_SECTION]: hasData(sectionCmsData) ? (
            <HeroBanner sliderData={sectionCmsData as CmsDataSectionTypes} />
         ) : null,
         [SECTIONS_ENUM.SPEED_GUIDE_SECTION]: <ConversationalWidget />,
         [SECTIONS_ENUM.SWITCH_TO_AIRTEL_SECTION]: hasData(sectionCmsData) ? (
            <SwitchToAirtel switchToAirtelData={sectionCmsData} />
         ) : null,
         [SECTIONS_ENUM.RECOMMENDATIONS_SECTION]: hasData(sectionCmsData) ? (
            <Recommendations sectionCmsData={sectionCmsData as CmsDataSectionTypes} />
         ) : null,
         [SECTIONS_ENUM.SUBSCRIPTIONS_SECTION]:
            hasData(sectionCmsData) && isMobile ? (
               <Subscriptions subscriptionsData={sectionCmsData as CmsDataSectionTypes} />
            ) : null,
         [SECTIONS_ENUM.B2B_SECTION]: hasData(sectionCmsData) ? (
            <B2BSection sectionCmsData={sectionCmsData} />
         ) : null,
      };
      return _sections[sectionName];
   };

   return renderSection();
};

export default Section;
