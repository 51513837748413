import { BASE_HREF_CONSTANTS } from '../../constants/AppConstants';
import { API_MAPPING, ENV_NAME } from './ApiConfigConstants';
import { GetQueryStringResponseTypes } from './types/ApiConfigTypes';

/**
 * returns the apiEnv passed in queryParams from the URL
 * @returns {GetQueryStringResponseTypes}
 */
const getQueryString = (): GetQueryStringResponseTypes => {
   const queryString = ((window.location && window.location.search) || '')
      .substring(1)
      .replace(/\?/g, '&')
      .split('&');
   const resultObj: any = {};
   queryString.forEach((keyVal) => {
      const key = keyVal.substring(0, keyVal.indexOf('='));
      const value = keyVal.substring(keyVal.indexOf('=') + 1);
      if (key !== '' && value !== '' && value !== 'null' && value !== 'undefined') {
         //@ts-ignore
         resultObj.apiEnv = value;
      }
   });
   return resultObj;
};

/**
 * get api base url
 * @param  {'PROD' | 'STAGE'} env
 * @returns {string}
 */
const getApiPointingBaseUrl = (env: 'PROD' | 'STAGE'): string => {
   let baseUrl = API_MAPPING[env] || API_MAPPING.PROD;
   const { apiEnv } = getQueryString(); // if we get from URL first check if its available in MAPPING else set as 'PROD'
   if (apiEnv) baseUrl = API_MAPPING[apiEnv] || API_MAPPING.PROD;
   return baseUrl;
};

const currentOrigin = window.location && window.location.origin;

const Environment = {
   [ENV_NAME.PROD]: {
      baseUrlContext: `${currentOrigin}`,
      cmsBaseEndPoint: getApiPointingBaseUrl('PROD'),
   },
   [ENV_NAME.QA]: {
      baseUrlContext: `${currentOrigin}`,
      cmsBaseEndPoint: getApiPointingBaseUrl('PROD'),
   },
   [ENV_NAME.DEV]: {
      baseUrlContext: `${currentOrigin}`,
      cmsBaseEndPoint: getApiPointingBaseUrl('STAGE'),
   },
};

const getBaseHrefUrlWithOrigin = () => `${currentOrigin}${BASE_HREF_CONSTANTS.default}`;

const getCurrEnv = () => {
   return process.env.REACT_APP_ENV || ENV_NAME.DEV;
};

const bootstrapEnv = () => {
   const ENV = getCurrEnv();
   return Environment[ENV];
};

export { getBaseHrefUrlWithOrigin, currentOrigin };
export default bootstrapEnv();
