import { LOGIN_CONSTANTS } from '../common/constants/AppConstants';
import * as EXCEPTIONS from '../common/constants/AppExceptions';
import { fireApiErrorLogSentry } from '../common/services/sentry/SentryService';

/**
 * check if object is null or has keys
 * @param obj
 * @returns {boolean}
 */
export const hasData = (obj: any): boolean => {
   return !!(obj && Object.keys(obj).length);
};

/**
 * returns errorMsg if present in AppExceptions
 * @param error
 * @returns {string}
 */
export const getErrorCode = (error: {
   data: { code?: string; errorCode?: string };
}): string => {
   const codePrefix = 'ERROR_CODE_';
   let errorCode = EXCEPTIONS.GENERIC_EXCEPTION;

   if (error?.data?.code) {
      errorCode = codePrefix + error?.data?.code;
   } else if (error.data.errorCode) {
      errorCode = codePrefix + error?.data?.errorCode;
   }

   return errorCode;
};

/**
 * handle navigation
 * @param {string} path
 */
export const navigateTo = (path?: string) => {
   if (!path || typeof path !== 'string') return;
   window.location.href = path;
};

export const checkMobile = () => {
   const mobileCheck = {
      Android: function () {
         return navigator.userAgent.match(/Android/i);
      },
      BlackBerry: function () {
         return navigator.userAgent.match(/BlackBerry/i);
      },
      iOS: function () {
         return navigator.userAgent.match(/iPhone/i);
      },
      Opera: function () {
         return navigator.userAgent.match(/Opera Mini/i);
      },
      Windows: function () {
         return navigator.userAgent.match(/IEMobile/i);
      },
      any: function () {
         return (
            mobileCheck.Android() ||
            mobileCheck.BlackBerry() ||
            mobileCheck.iOS() ||
            mobileCheck.Opera() ||
            mobileCheck.Windows() ||
            window.innerWidth < 768
         );
      },
   };
   return mobileCheck.any() ? true : false;
};

/**
 * check if session storage is supported
 * @returns {boolean}
 */
export const isSessionStorageSupported = (): boolean => {
   const testKey = 'test'
   try {
      const storage = window?.sessionStorage;
      storage.setItem(testKey, '1');
      storage.removeItem(testKey);
      return true;
   } catch (error) {
      fireApiErrorLogSentry(
         'utils.ts-->isSessionStorageSupported-->error while checking if Session Storage is Supported',
         error
      );
      return false;
   }
};

/**
 * set in session storage
 * @param {string} key
 * @param {any} obj
 */
export const setInSessionStorage = (key: string, obj: any) => {
   if (isSessionStorageSupported()) {
      sessionStorage[key] = obj;
   }
};

/**
 *
 * @param {string} key
 * @returns {boolean}
 */
export const getFromSessionStorage = (key: string): boolean => {
   if (isSessionStorageSupported()) {
      const fromSessionStorage = sessionStorage[key];
      return fromSessionStorage ? fromSessionStorage : false;
   }
   return false;
};

/**
 * check if local storage is supported
 * @returns {boolean}
 */
export const isLocalStorageSupported = (): boolean => {
   const testKey = 'test'
   try {
      const storage = window?.localStorage;
      storage.setItem(testKey, '1');
      storage.removeItem(testKey);
      return true;
   } catch (error) {
      fireApiErrorLogSentry(
         'utils.ts-->isLocalStorageSupported-->error while checking if Local Storage is Supported',
         error
      );
      return false;
   }
};

/**
 * set in local storage
 * @param {string} key
 * @param {any} obj
 */
export const setInLocalStorage = (key: string, obj: any) => {
   if (isLocalStorageSupported()) {
      localStorage[key] = obj;
   }
};

/**
 * get value from local storage
 * @param {string} key
 */
export const getFromLocalStorage = (key: string) => {
   if (isLocalStorageSupported()) {
      const fromLocalStorage = localStorage[key];
      return fromLocalStorage ? fromLocalStorage : null;
   }
};

/*
 * generic function to return whether a user is logged in or not
 * @returns {boolean}
 */
export const isUserLoggedIn = () => {
   return (
      isLocalStorageSupported() &&
      localStorage?.getItem(LOGIN_CONSTANTS.dynamicToken) !== null &&
      localStorage?.getItem(LOGIN_CONSTANTS.uid) !== null &&
      localStorage?.getItem(LOGIN_CONSTANTS.staticToken) !== null &&
      localStorage?.getItem(LOGIN_CONSTANTS.loggedInUser) !== null
   );
};
