import React, { FC, useEffect, useState } from 'react';
import {
   Typography,
   GridRow,
   Button,
   Image,
   GridColumn,
   useGetMediaBreakpoint,
   AppNudge,
} from '@airtel-web/legos';
import { navigateTo } from '../../../../utils/utils';
import { OfferSectionPropTypes } from './types/OfferSectionPropTypes';
import { APP_NAME, STATIC_ASSETS_URL } from '../../../../common/constants/AppConstants';
import { fireAnalytics } from '../../service/HomePageAnalyticsService';
import { ANALYTICS_CONSTANTS } from '../../../../common/constants/AnalyticsConstants';
import './OfferSection.scss';

const OfferSection: FC<OfferSectionPropTypes> = (props) => {
   const { sectionCmsData } = props;
   const { dataAttributes = {} } =
      (sectionCmsData?.widgetContainers && sectionCmsData?.widgetContainers[0]) || {};
   const { imageUrl, text, cta, ctaLink } = dataAttributes;
   const [showAppNudge, setShowAppNudge] = useState(false);
   const mediaBreakpoints = useGetMediaBreakpoint();
   const isSmallDevice = mediaBreakpoints === 'xs' || mediaBreakpoints === 'sm';

   const ctaEvents = {
      section: ANALYTICS_CONSTANTS.getApp,
      eventAction: ANALYTICS_CONSTANTS.impression,
      eventLabel: cta?.toLowerCase(),
      eventValue: text?.toLowerCase(),
      customLabel: ANALYTICS_CONSTANTS.button,
      isInteractive: '0',
      horizontalPosition: '0',
      verticalPosition: '0',
   };

   useEffect(() => {
      fireAnalytics(ctaEvents, 'getAppButton');
   }, []);

   const onCtaClick = () => {
      fireAnalytics({
         ...ctaEvents,
         eventAction: ANALYTICS_CONSTANTS.click,
         isInteractive: '1',
         customValue: '',
      });
      isSmallDevice ? navigateTo(ctaLink) : setShowAppNudge(true)
   };

   const onAppNudgeCloseHandler = () => {
      setShowAppNudge(false);
   };

   return (
      <div className="offer-section-container">
         <GridRow fitContent={false} className="offer-section-row">
            <GridColumn>
               <div className="offer-item">
                  {
                     <Image
                        alt="offer icon"
                        src={imageUrl || `${STATIC_ASSETS_URL}/images/offer-icon.jpg`}
                        width="36"
                        height="36"
                        className="offer-icon"
                        useBaseUrl={false}
                        draggable={false}
                     />
                  }
                  {text && (
                     <Typography
                        type="body-para-xs-bold"
                        className="offer-text"
                     >
                        {text}
                     </Typography>
                  )}
                  {cta && (
                     <Button
                        id="getAppButton"
                        buttonType="alternate-light"
                        className="get-app-btn"
                        onClick={onCtaClick}
                     >
                        {cta}
                     </Button>
                  )}
                  {!isSmallDevice && (
                     <AppNudge
                        appName={APP_NAME}
                        onCloseHandler={onAppNudgeCloseHandler}
                        isOpen={showAppNudge}
                     />
                  )}
               </div>
            </GridColumn>
         </GridRow>
      </div>
   );
};

export default OfferSection;
