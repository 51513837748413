import {
   PrepaidIcon,
   PostpaidIcon,
   DthIcon,
   FiberIcon,
   OneAirtelIcon,
   PrepaidFilledIcon,
   DthFilledIcon,
   FiberFilledIcon,
   PostpaidFilledIcon,
} from '@airtel-web/legos';
import { TEST_PATTERNS } from '../../../../common/constants/AppConstants';

export const LOB = {
   prepaid: 'PREPAID',
   postpaid: 'POSTPAID',
   dth: 'DTH',
   dsl: 'DSL',
   airtelBlack: 'AIRTEL_BLACK',
};

export const BADGE_LIST = [
   {
      lob: 'PREPAID',
      displayName: 'Prepaid',
      prefixIcon: PrepaidIcon,
      prefixFilledIcon: PrepaidFilledIcon,
   },
   {
      lob: 'POSTPAID',
      displayName: 'Postpaid',
      prefixIcon: PostpaidIcon,
      prefixFilledIcon: PostpaidFilledIcon,
   },
   {
      lob: 'DTH',
      displayName: 'DTH',
      prefixIcon: DthIcon,
      prefixFilledIcon: DthFilledIcon,
   },
   {
      lob: 'DSL',
      displayName: 'Wi-Fi',
      prefixIcon: FiberIcon,
      prefixFilledIcon: FiberFilledIcon,
   },
   {
      lob: 'AIRTEL_BLACK',
      displayName: 'Airtel Black',
      prefixIcon: OneAirtelIcon,
      prefixFilledIcon: OneAirtelIcon,
   },
];

export const LOB_SECTION_DETAILS = {
   [LOB.prepaid]: {
      inputType: 'tel',
      btnLabel: 'RECHARGE',
      placeholder: 'Enter mobile number',
      minLength: 10,
      maxLength: 10,
      regEx: TEST_PATTERNS.numeric,
      errorMsg: 'Please enter a valid mobile number.',
   },
   [LOB.postpaid]: {
      inputType: 'tel',
      btnLabel: 'PAY BILL',
      placeholder: 'Enter mobile number',
      minLength: 10,
      maxLength: 10,
      regEx: TEST_PATTERNS.numeric,
      errorMsg: 'Please enter a valid mobile number.',
   },
   [LOB.dth]: {
      inputType: 'tel',
      btnLabel: 'RECHARGE',
      placeholder: 'Enter mobile number/DTH ID',
      minLength: 10,
      maxLength: 10,
      regEx: TEST_PATTERNS.numeric,
      errorMsg: 'Please enter a valid mobile number/service ID.',
   },
   [LOB.dsl]: {
      inputType: 'text',
      btnLabel: 'PAY BILL',
      placeholder: 'Enter mobile number/DSL ID',
      minLength: 5,
      maxLength: 20,
      regEx: TEST_PATTERNS.alphaNumeric,
      errorMsg: 'Please enter a valid mobile number/service ID.',
   },
   [LOB.airtelBlack]: {
      inputType: 'tel',
      btnLabel: 'PAY BILL',
      placeholder: 'Enter mobile number',
      minLength: 10,
      maxLength: 10,
      regEx: TEST_PATTERNS.numeric,
      errorMsg: 'Please enter a valid mobile number.',
   },
};
