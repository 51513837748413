export const RECOMMENDATIONS_SLIDER_BREAKPOINTS = {
   sm: {
      slidesToScroll: 1,
      gutter: 16,
      slidesToShow: 1,
      seekGap: 12,
   },
   md: {
      slidesToScroll: 1,
      gutter: 37,
      slidesToShow: 2,
      seekGap: 12,
   },
   lg: {
      slidesToScroll: 1,
      gutter: 37,
      slidesToShow: 3,
      seekGap: 0,
   },
};

export const RECOMMENDATIONS_SECTION_TEST_ID = {
   slider: 'slider',
   prevSlideButton: 'prevSlideButton',
   nextSlideButton: 'nextSlideButton',
   slide: 'slide',
   currentBannerDiv: 'currentBannerDiv',
};
