export const ANALYTICS_CONSTANTS = {
    mweb: 'mweb',
    dweb: 'dweb',
    webHomepage: 'web homepage',
    homepage: 'homepage',
    pageopen: 'pageopen',
    pageloaded: 'pageloaded',
    pageclose: 'pageclose',
    error: 'error',
    topNav: 'top nav',
    impression: 'impression',
    toast: 'toast',
    click: 'click',
    dismiss: 'dismiss',
    close: 'close',
    button: 'button',
    offerCard: 'offer card',
    primary: 'primary',
    card: 'card',
    arrow: 'arrow',
    leftArrow: 'left arrow',
    rightArrow: 'right arrow',
    getApp: 'get app',
    rechargePayBill: 'recharge and pay bill',
    filter: 'filter',
    textbox: 'textbox',
    recharge: 'recharge',
    switchToAirtel: 'switch to airtel',
    selectAPlan: 'select a plan and order sim get sim home-delivered',
    recommendedForYou: 'recommended for you',
    subscriptionForYou: 'subscription for you',
    forYourBusiness: 'for your business',
    whatSpeedDoINeed: 'what speed do I need',
    speed: 'speed',
    enterNumber: 'enter number',
    enterName: 'enter name',
    buyNewConnection: 'buy a new connection',
 };