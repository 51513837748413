import React, { useEffect } from 'react';
import {
   Button,
   useGetMediaBreakpoint,
   GridColumn,
   GridRow,
   Typography,
} from '@airtel-web/legos';
import { HeroBannerSlidePropTypes } from './types/HeroBannerSlidePropTypes';
import { navigateTo } from '../../../../../../utils/utils';
import { HERO_BANNER_SLIDE_TEST_ID } from './HeroBannerSlideConstants';
import { fireAnalytics } from '../../../../service/HomePageAnalyticsService';
import { ANALYTICS_CONSTANTS } from '../../../../../../common/constants/AnalyticsConstants';
import colors from '@airtel-web/legos/lib/styles/variables/colors.module.scss';
import gradients from '@airtel-web/legos/lib/styles/variables/gradients.module.scss';
import './HeroBannerSlide.scss';

const HeroBannerSlide = (props: HeroBannerSlidePropTypes) => {
   const { attributes, index, customCampaignId, currentBanner } = props;
   const mediaBreakpoint = useGetMediaBreakpoint();
   const isMobile = mediaBreakpoint === 'xs' || mediaBreakpoint === 'sm';
   const isMdDevice = mediaBreakpoint === 'md';

   const primaryCtaEvents = {
      section: ANALYTICS_CONSTANTS.offerCard,
      subSection: ANALYTICS_CONSTANTS.primary,
      eventAction: ANALYTICS_CONSTANTS.impression,
      eventLabel: attributes.primaryCtaText?.toLowerCase(),
      customLabel: ANALYTICS_CONSTANTS.button,
      isInteractive: '0',
      horizontalPosition: index.toString(),
      verticalPosition: '2',
   };

   const secondaryCtaEvents = {
      ...primaryCtaEvents,
      eventLabel: attributes.secondaryCtaText,
   };

   const heroBannerEvents = {
      section: ANALYTICS_CONSTANTS.offerCard,
      subSection: ANALYTICS_CONSTANTS.primary,
      eventAction: ANALYTICS_CONSTANTS.impression,
      eventLabel: attributes.headingText?.toLowerCase(),
      eventValue: attributes.subHeadingText?.toLowerCase(),
      customLabel: ANALYTICS_CONSTANTS.card,
      isInteractive: '0',
      offerSource: attributes.offerSource,
      offerId: attributes.offerId,
      customCampaignId: customCampaignId,
      horizontalPosition: index.toString(),
      verticalPosition: '2',
   };

   useEffect(() => {
      fireAnalytics(heroBannerEvents, `hero-banner-content-${index}`);
      fireAnalytics(primaryCtaEvents, `primaryCta-${index}`);
      fireAnalytics(secondaryCtaEvents, `secondaryCta-${index}`);
   }, []);

   const onPrimaryCtaClick = () => {
      fireAnalytics({
         ...primaryCtaEvents,
         eventAction: ANALYTICS_CONSTANTS.click,
         isInteractive: '1',
      });
      navigateTo(attributes.primaryCtaLink);
   };

   const onSecondaryCtaClick = () => {
      fireAnalytics({
         section: ANALYTICS_CONSTANTS.offerCard,
         subSection: ANALYTICS_CONSTANTS.primary,
         eventAction: ANALYTICS_CONSTANTS.click,
         eventLabel: attributes.secondaryCtaText?.toLowerCase(),
         customLabel: ANALYTICS_CONSTANTS.button,
         isInteractive: '1',
         horizontalPosition: index.toString(),
         verticalPosition: '2',
      });
      navigateTo(attributes.secondaryCtaLink || '');
   };

   const getSlideBackground = () => {
      return (
         (attributes.backgroundColor.includes('gradient')
            ? gradients[attributes.backgroundColor.split(':')[1]]
            : attributes.backgroundColor) || colors.lilac200
      );
   };

   return (
      <div
         className="hero-banner-slide-root"
         style={{
            background: getSlideBackground(),
         }}
      >
         <div
            className="hero-banner-bg-image"
            style={{
               backgroundImage: `url(${isMobile ? attributes.mobileImage : attributes.image})`,
            }}
         >
            <GridRow className="hero-banner-container">
               <GridColumn
                  alignSelf="center"
                  breakpoints={['col-lg-6', 'col-md-4', 'col-sm-9']}
                  className="hero-banner-content-container"
               >
                  <GridRow
                     noMargin
                     direction="row-as-column"
                     className="hero-banner-content"
                     id={`hero-banner-content-${index}`}
                  >
                     <GridColumn noGutter className="hero-banner-heading">
                        <Typography
                           data-testid={HERO_BANNER_SLIDE_TEST_ID.heroBannerHeading}
                           type={isMobile || isMdDevice ? 'h6-bold' : 'h4-bold'}
                           color={attributes.headingColor || colors.black900}
                        >
                           {attributes.headingText}
                        </Typography>
                     </GridColumn>
                     <GridColumn noGutter className="hero-banner-subheading">
                        <Typography
                           data-testid={HERO_BANNER_SLIDE_TEST_ID.heroBannerSubheading}
                           type={isMobile || isMdDevice ? 'body-para-sm' : 'body-para-lg'}
                           color={attributes.subheadingColor || colors.black700}
                           dangerouslySetInnerHTML={{ __html: attributes.subHeadingText }}
                           >
                        </Typography>
                     </GridColumn>
                     <GridColumn noGutter>
                        <div className="hero-banner-cta-container">
                           <Button
                              id={`primaryCta-${index}`}
                              onClick={onPrimaryCtaClick}
                              buttonType={
                                 (attributes.previousSlideButtonType as any) || 'primary'
                              }
                              tabIndex={currentBanner !== index ? -1 : 0}
                              size="md"
                           >
                           <span 
                            dangerouslySetInnerHTML={{ __html: attributes.primaryCtaText }}/>
                           </Button>
                           {attributes.secondaryCtaText &&
                              attributes.secondaryCtaText !== '' &&
                              attributes.secondaryCtaLink !== '' && (
                                 <Button
                                    id={`secondaryCta-${index}`}
                                    buttonType={
                                       (attributes.previousSlideButtonType as any) ||
                                       'tertiary'
                                    }
                                    size="md"
                                    onClick={onSecondaryCtaClick}
                                    tabIndex={currentBanner !== index ? -1 : 0}
                                 >
                                    {attributes.secondaryCtaText}
                                 </Button>
                              )}
                        </div>
                     </GridColumn>
                  </GridRow>
               </GridColumn>
            </GridRow>
         </div>
      </div>
   );
};

export default HeroBannerSlide;
