import React, { FC, useEffect, useRef, useState } from 'react';
import { Footer, getMediaBreakpoint } from '@airtel-web/legos';
import Section from './view/section';
import LandingSkeleton from './view/skeleton-loading/view/landing-skeleton/LandingSkeleton';
import HOMEPAGE_LAYOUT_INFO from '../../common/assets/homePageLayout.json';
import HOMEPAGE_LAYOUT_MOBILE_INFO from '../../common/assets/homePageLayoutMobile.json';
import { apiFallback, getCmsData } from './service/HomePageService';
import { CmsDataSectionTypes } from './types/CmsDataTypes';
import { fireAnalytics } from './service/HomePageAnalyticsService';
import { ANALYTICS_CONSTANTS } from '../../common/constants/AnalyticsConstants';
import CookiesSection from './view/cookies-section';
import { APP_NAME } from '../../common/constants/AppConstants';

const HomePage: FC = () => {
   const [cmsSectionList, setCmsSectionList] = useState<Array<CmsDataSectionTypes>>([]);
   const [isLoading, setLoading] = useState<boolean>(true);
   const timeoutRef = useRef<NodeJS.Timeout>();
   const loadedFromCache = useRef<boolean>(false);
   const mediaBreakpoint = getMediaBreakpoint();
   const isSmallDevice = mediaBreakpoint === 'xs';
   const LAYOUT_DATA = isSmallDevice? HOMEPAGE_LAYOUT_MOBILE_INFO.data : HOMEPAGE_LAYOUT_INFO.data ;
   const RESPONSE_LOAD_TIMEOUT = 3000;

   useEffect(() => {
      fetchCmsData();
   }, []);

   const data = {
      journey: ANALYTICS_CONSTANTS.webHomepage,
      eventCategory: ANALYTICS_CONSTANTS.webHomepage,
      prop0: ANALYTICS_CONSTANTS.homepage,
   };

   /**
    * load data from cache if api response delays
    */
   const loadFromCacheIfDelay = () => {
      loadedFromCache.current = false;
      timeoutRef.current = setTimeout(async () => {
         const cacheResponse = await apiFallback();
         if (cacheResponse?.sections) {
            setCmsSectionList(cacheResponse.sections);
            setLoading(false);
            loadedFromCache.current = true;
         }
      }, RESPONSE_LOAD_TIMEOUT);
   };

   /**
    * get data from CMS - to be used in case of CMS powered sections
    */
   const fetchCmsData = async () => {
      try {
         loadFromCacheIfDelay();
         const response = await getCmsData();
         clearTimeout(timeoutRef.current);
         timeoutRef.current = undefined;
         if (loadedFromCache.current) return;
         if (response?.sections) {
            setCmsSectionList(response?.sections);
         }
         setLoading(false);
      } catch (error: any) {
         setLoading(false);
         fireAnalytics({
            section: ANALYTICS_CONSTANTS.webHomepage,
            eventAction: ANALYTICS_CONSTANTS.error,
            eventLabel: 'any',
            eventValue: 'any',
            isInteractive: '0',
            error: error?.data?.errors[0]?.message,
            horizontalPosition: '-1',
            verticalPosition: '-1',
         });
      }
   };

   return (
      <>
         {isLoading ? (
            <LandingSkeleton />
         ) : (
            <>
               {!!LAYOUT_DATA?.length &&
                  LAYOUT_DATA.map((item) => {
                     return (
                        <Section
                           sectionDetails={item}
                           cmsSectionList={cmsSectionList}
                           key={item.sectionName}
                        />
                     );
                  })}
               <Footer trackingData={data} appName={APP_NAME}/>
               <CookiesSection />
            </>
         )}
      </>
   );
};

export default HomePage;
