import React, { FC, useEffect, useRef } from 'react';
import HomePage from './modules/home-page';
import { ErrorBoundary } from '@airtel-lego/sentry';
import { FallbackPage } from './modules/home-page/view/fallback-page';
import { init } from '@airtel-web/clickstream';
import { fireAnalytics } from './modules/home-page/service/HomePageAnalyticsService';
import { ANALYTICS_CONSTANTS } from './common/constants/AnalyticsConstants';

const App: FC = () => {
   const startTime = useRef(0);

   useEffect(() => {
      init({ appName: ANALYTICS_CONSTANTS.homepage });
      firePageOpen();

      const endTime = Date.now();
      fireAnalytics({
         section: ANALYTICS_CONSTANTS.webHomepage,
         eventAction: ANALYTICS_CONSTANTS.pageloaded,
         eventLabel: '',
         eventValue: '',
         customLabel: '',
         customValue: '',
         isInteractive: '0',
         timeSpent: '0',
         horizontalPosition: '-1',
         verticalPosition: '-1',
         loadTimeInMilliseconds: (endTime - startTime.current).toString(),
      });

      window.addEventListener('beforeunload', () => {
         firePageClose();
      });

      window.addEventListener('visibilitychange', () => {
         if (document.visibilityState == 'visible') {
            firePageOpen();
         } else {
            firePageClose();
         }
      });
   }, []);

   const firePageClose = () => {
      const endTime1 = Date.now();
      fireAnalytics({
         section: ANALYTICS_CONSTANTS.webHomepage,
         eventAction: ANALYTICS_CONSTANTS.pageclose,
         eventLabel: '',
         eventValue: '',
         customLabel: '',
         customValue: '',
         isInteractive: '0',
         loadTimeInMilliseconds: '0',
         timeSpent: ((endTime1 - startTime.current) / 1000).toString(),
         horizontalPosition: '-1',
         verticalPosition: '-1',
      });
   };

   const firePageOpen = () => {
      startTime.current = Date.now();
      fireAnalytics({
         section: ANALYTICS_CONSTANTS.webHomepage,
         eventAction: ANALYTICS_CONSTANTS.pageopen,
         eventLabel: '',
         eventValue: '',
         customLabel: '',
         customValue: '',
         isInteractive: '0',
         timeSpent: '0',
         horizontalPosition: '-1',
         verticalPosition: '-1',
      });
   };

   return (
      <ErrorBoundary fallback={FallbackPage}>
         <HomePage />
      </ErrorBoundary>
   );
};

export default App;
