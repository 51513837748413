import React, { FC, useEffect } from 'react';
import {
   Image,
   Typography,
   Button,
   useGetMediaBreakpoint,
} from '@airtel-web/legos';
import { RecommendationSlidePropTypes } from './types/RecommendationSlidePropTypes';
import { navigateTo } from '../../../../../utils/utils';
import { fireAnalytics } from '../../../service/HomePageAnalyticsService';
import { RECOMMENDATIONS_SECTION_TEST_ID } from '../RecommendationsConstants';
import { ANALYTICS_CONSTANTS } from '../../../../../common/constants/AnalyticsConstants';
import colors from '@airtel-web/legos/lib/styles/variables/colors.module.scss';
import './RecommendationsSlide.scss';

const RecommendationsSlide: FC<RecommendationSlidePropTypes> = (props) => {
   const { dataAttributes, index, customCampaignId } = props;
   const {
      label,
      icon,
      heading,
      content,
      cta,
      ctaLink,
      secondaryCta,
      secondaryCtaLink,
      offerId,
      offerSource,
   } = dataAttributes;
   const mediaBreakpoint = useGetMediaBreakpoint();
   const isMobile = mediaBreakpoint === 'xs' || mediaBreakpoint === 'sm';

   const getHeadingValue = () => {
      const headingEl = document.getElementById(`slide-heading-${index}`);
      return headingEl ? headingEl.textContent || '' : '';
   };

   const cardEvents = {
      section: ANALYTICS_CONSTANTS.recommendedForYou,
      subSection: label?.toLowerCase(),
      eventAction: ANALYTICS_CONSTANTS.impression,
      eventValue: content?.toLowerCase(),
      customValue: cta?.toLowerCase(),
      customLabel: ANALYTICS_CONSTANTS.card,
      isInteractive: '0',
      offerSource: offerSource,
      customCampaignId: customCampaignId,
      offerId: offerId,
      horizontalPosition: index.toString(),
      verticalPosition: '6',
   };

   useEffect(() => {
      const event = { ...cardEvents, eventLabel: getHeadingValue()?.toLowerCase() };
      fireAnalytics(event, `recommendations-slide-${index}`);
   }, []);

   const onCtaClick = () => {
      fireAnalytics({
         ...cardEvents,
         eventLabel: cta?.toLowerCase(),
         eventAction: ANALYTICS_CONSTANTS.click,
         customLabel: ANALYTICS_CONSTANTS.button,
         eventValue: getHeadingValue()?.toLowerCase(),
         customValue: 'NA',
         isInteractive: '1',
      });
      navigateTo(ctaLink);
   };

   const onSecondaryCtaClick = () => {
      fireAnalytics({
         ...cardEvents,
         eventLabel: secondaryCta?.toLowerCase(),
         eventAction: ANALYTICS_CONSTANTS.click,
         customLabel: ANALYTICS_CONSTANTS.button,
         eventValue: getHeadingValue()?.toLowerCase(),
         customValue: 'NA',
         isInteractive: '1',
      });
      navigateTo(secondaryCtaLink);
   };

   return (
      <div
         id={`recommendations-slide-${index}`}
         className="recommendations-card"
         data-testid={`${RECOMMENDATIONS_SECTION_TEST_ID.slide}_${index}`}
      >
         <Image
            src={icon}
            useBaseUrl={false}
            alt="recommendations slide icon image"
            className="recommendations-card-image"
            width="156"
            height="117"
            draggable={false}
         />
         <div className="recommendations-card-content">
            <Typography
               color={colors.TRed500}
               type="body-single-line-sm-bold"
               className="recommendations-card-title"
            >
               {label}
            </Typography>
            {heading && (
               <Typography
                  id={`slide-heading-${index}`}
                  type={!isMobile ? 'body-para-xl' : 'body-para-lg'}
                  className="recommendations-card-sub-title"
                  color={colors.black800}
                  dangerouslySetInnerHTML={{ __html: heading }}
               />
            )}
            {content && (
               <Typography
                  type={!isMobile ? 'body-para-sm' : 'body-para-xs'}
                  color={colors.black700}
                  className="slide-content"
               >
                  {content}
               </Typography>
            )}
            <div className="recommendations-card-btn-black">
               {cta && (
                  <Button buttonType="primary" className="buy-now-btn" onClick={onCtaClick}>
                     {cta}
                  </Button>
               )}
               {secondaryCta && (
                  <div className="recommendations-card-btn">
                     <Button
                        buttonType="tertiary"
                        className="learn-more-btn"
                        onClick={onSecondaryCtaClick}
                     >
                        {secondaryCta}
                     </Button>
                  </div>
               )}
            </div>
         </div>
      </div>
   );
};

export default RecommendationsSlide;
