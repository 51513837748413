import { navigateTo } from '../../../../../utils/utils';
import { LOB } from '../RechargeSectionConstants';

/**
 * redirect to Lob page
 * @param {string} lob
 * @param {string} inputVal
 */
export const redirectToLobPage = (lob: string, inputVal = '') => {
   switch (lob) {
      case LOB.prepaid:
         navigateTo(
            `https://www.airtel.in/recharge/prepaid/?siNumber=${inputVal}&icid=recharge_rail`
         );
         break;
      case LOB.postpaid:
         navigateTo(
            `https://www.airtel.in/postpaid-bill-pay?siNumber=${inputVal}&icid=recharge_rail`
         );
         break;
      case LOB.dth:
         navigateTo(
            `https://www.airtel.in/dth-recharge?siNumber=${inputVal}&icid=recharge_rail`
         );
         break;
      case LOB.dsl:
         navigateTo(
            `https://www.airtel.in/broadband-bill-pay?siNumber=${inputVal}&icid=recharge_rail`
         );
         break;
      case LOB.airtelBlack:
         navigateTo(
            `https://www.airtel.in/recharge-and-billpay/airtel-black/?si=${inputVal}&icid=recharge_rail`
         );
         break;
      default:
         navigateTo(
            `https://www.airtel.in/recharge/prepaid/?siNumber=${inputVal}&icid=recharge_rail`
         );
   }
};
