import { fireLogWithSentryBase } from '@airtel-lego/sentry';

/**
 * wrapper function for sentry logging in case of error
 * @param {string} errorMsg - Custom error string
 * @param {object} errorException - thrown error/ exception
 * @param {object} contextValues - object with context information for Sentry.captureException method
 */
export const fireLogWithSentry = (errorMsg: string, errorException: any = {}, contextValues: { tags?: any; extra?: any; [key: string]: any } = {}) => {
    const _tags: { [key: string]: string } = {};

    const _contextValues = { ...contextValues, tags: { ...contextValues.tags, ..._tags } };

    const apiErrorInfo = { errorCode: 'errorcode', errorMessage: 'errortitle' };
    fireLogWithSentryBase(errorMsg, errorException, _contextValues, apiErrorInfo);
};

/**
 * wrapper function for sentry logging in case of API error
 * @param {string} errorMsg - Custom error string
 * @param {object} errorException - thrown error/ exception
 * @param {object} contextValues - object with context information for Sentry.captureException method
 */
export const fireApiErrorLogSentry = (errorMsg: string, errorException: any = {}, contextValues: { tags?: any; extra?: any; [key: string]: any } = {}) => {
    const _contextValues = { ...contextValues, tags: { ...contextValues.tags, isApiError: true } };
    fireLogWithSentry(errorMsg, errorException, _contextValues);
};
