import React, { useEffect, useRef, useState } from 'react';
import {
   GridRow,
   GridColumn,
   IconButton,
   Slider,
   SliderRefTypes,
   Typography,
   SlideDataTypes,
   SliderOnChangeTypes,
   LeftArrowIcon,
   RightArrowIcon,
   useGetMediaBreakpoint,
} from '@airtel-web/legos';
import SubscriptionCard from './view/subscription-card/SubscriptionCard';
import { SubscriptionsPropTypes } from './types/SubscriptionsPropTypes';
import {
   SUBSCRIPTIONS_SECTION_TEST_ID,
   SUBSCRIPTION_SLIDER_AUTOPLAY_DELAY,
   SUBSCRIPTION_SLIDER_BREAKPOINTS,
} from './SubscriptionsConstants';
import { fireAnalytics } from '../../service/HomePageAnalyticsService';
import { ANALYTICS_CONSTANTS } from '../../../../common/constants/AnalyticsConstants';
import colors from '@airtel-web/legos/lib/styles/variables/colors.module.scss';
import './Subscriptions.scss';

const Subscriptions = (props: SubscriptionsPropTypes) => {
   const { subscriptionsData } = props;
   const mediaBreakpoint = useGetMediaBreakpoint();
   const isMobile = mediaBreakpoint === 'xs' ;
   const isMdDevice = mediaBreakpoint === 'md' || mediaBreakpoint === 'sm';
   const [dataList, setDataList] = useState<Array<SlideDataTypes>>([]);
   const [currentSubscription, setCurrentSubscription] = useState<SliderOnChangeTypes>();
   const sliderRef = useRef<SliderRefTypes>(null);

   const handleCurrentSubscriptionChange = (data: SliderOnChangeTypes) => {
      if (
         data.left !== currentSubscription?.left ||
         data.right !== currentSubscription?.right
      ) {
         setCurrentSubscription(data);
      }
   };

   const handleBannerNext = () => {
      if (sliderRef.current?.nextSlide) sliderRef.current.nextSlide();
      fireAnalytics({
         section: ANALYTICS_CONSTANTS.subscriptionForYou,
         eventAction: ANALYTICS_CONSTANTS.click,
         eventLabel: ANALYTICS_CONSTANTS.rightArrow,
         eventValue: '',
         customLabel: ANALYTICS_CONSTANTS.button,
         customValue: '',
         isInteractive: '1',
         horizontalPosition: '1',
         verticalPosition: '7',
      });
   };

   const handleBannerPrev = () => {
      if (sliderRef.current?.prevSlide) sliderRef.current.prevSlide();
      fireAnalytics({
         section: ANALYTICS_CONSTANTS.subscriptionForYou,
         eventAction: ANALYTICS_CONSTANTS.click,
         eventLabel: ANALYTICS_CONSTANTS.leftArrow,
         eventValue: '',
         customLabel: ANALYTICS_CONSTANTS.button,
         customValue: '',
         isInteractive: '1',
         horizontalPosition: '0',
         verticalPosition: '7',
      });
   };

   const checkSliderControlsDisplay = () => {
      if (
         (isMobile && subscriptionsData.widgetContainers?.length > 2) ||
         (isMdDevice && subscriptionsData.widgetContainers?.length > 4) ||
         (!isMobile && !isMdDevice && subscriptionsData.widgetContainers?.length > 6)
      ) {
         return true;
      }
      return false;
   };

   useEffect(() => {
      const _dataList: Array<SlideDataTypes> = [];
      subscriptionsData.widgetContainers?.forEach((widgetContainer, index) => {
         const item = (
            <SubscriptionCard
               subscriptionData={widgetContainer.dataAttributes}
               index={index}
               customCampaignId={subscriptionsData.dataAttributes.customCampaignId}
            />
         );

         if (widgetContainer.dataAttributes) {
            _dataList.push({ children: item });
         }
      });
      setDataList(_dataList);
   }, []);

   const pageSlide = isMdDevice
      ? `${Math.ceil((currentSubscription?.currIndex || 0) + 1)}/${Math.ceil(dataList.length)}`
      : `${Math.ceil((currentSubscription?.currIndex || 0) + 2 / 3)}/${Math.ceil(dataList.length / 2)}`;

   const autoPlayDelay =
      subscriptionsData?.dataAttributes?.autoPlayDelay >=
      SUBSCRIPTION_SLIDER_AUTOPLAY_DELAY
         ? subscriptionsData.dataAttributes.autoPlayDelay
         : SUBSCRIPTION_SLIDER_AUTOPLAY_DELAY;

   const autoPlay = subscriptionsData.dataAttributes?.autoPlay
      ? subscriptionsData.dataAttributes.autoPlay
      : false;

   const slideTransitionSpeed = subscriptionsData.dataAttributes?.slideTransitionSpeed
      ? subscriptionsData.dataAttributes.slideTransitionSpeed
      : 1000;

   return (
      <div className="subscriptions-root icon-button-black800">
         <GridRow noMargin direction="row-as-column" className="subscription-root-grid">
            <GridColumn noGutter alignSelf="center" className="subscriptions-heading">
               <Typography
                  type={isMobile ? 'body-single-line-lg-bold' : 'h4-bold'}
                  color={colors.black900}
               >
                  Subscriptions for you
               </Typography>
            </GridColumn>
            <GridColumn layout="comfortable" noGutter={isMobile}>
               {dataList.length > 0 && (
                  <Slider
                     data-testid={SUBSCRIPTIONS_SECTION_TEST_ID.slider}
                     ref={sliderRef}
                     autoPlay={autoPlay}
                     autoPlayDelay={autoPlayDelay}
                     loop
                     breakpoints={SUBSCRIPTION_SLIDER_BREAKPOINTS}
                     data={dataList}
                     distanceToSwipeSlide={50}
                     onSlideChangeHandler={handleCurrentSubscriptionChange}
                     swipeGesture={isMobile}
                     slideTransitionSpeed={slideTransitionSpeed}
                  />
               )}
            </GridColumn>
            {checkSliderControlsDisplay() && (
               <GridColumn className="controls-column-subscription">
                  <GridRow className="subscriptions-slider-controls">
                     <IconButton
                        data-testid={SUBSCRIPTIONS_SECTION_TEST_ID.prevSlideButton}
                        size="xs"
                        id="previousSlide"
                        type="primary"
                        icon={LeftArrowIcon}
                        isDisabled={currentSubscription?.left === 0}
                        onClick={handleBannerPrev}
                     />
                     <Typography
                        type="body-single-line-xs"
                        color={colors.black600}
                        data-testid={SUBSCRIPTIONS_SECTION_TEST_ID.currentBannerDiv}
                     >
                        {pageSlide}
                     </Typography>
                     <IconButton
                        data-testid={SUBSCRIPTIONS_SECTION_TEST_ID.nextSlideButton}
                        size="xs"
                        id="nextSlide"
                        type="primary"
                        icon={RightArrowIcon}
                        onClick={handleBannerNext}
                     />
                  </GridRow>
               </GridColumn>
            )}
         </GridRow>
      </div>
   );
};

export default Subscriptions;
