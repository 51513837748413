import { trackEvent, TrackEventTypes } from '@airtel-web/clickstream';
import { getIntersectionObserver, getMediaBreakpoint } from '@airtel-web/legos';
import { ANALYTICS_CONSTANTS } from '../../../common/constants/AnalyticsConstants';

const eventsMap: { [name: string]: TrackEventTypes } = {};

const observer = getIntersectionObserver({
   threshold: [0.1],
   callback: (element: HTMLElement) => {
      trackEvent(eventsMap[element.id]);
   },
});

export const fireAnalytics = (data: TrackEventTypes, id?: string) => {
   const mediaBreakpoint = getMediaBreakpoint();
   const isMobile = mediaBreakpoint === 'sm' || mediaBreakpoint === 'xs';
   data = {
      section:'',
      subSection: '',
      loadTimeInMilliseconds: '0',
      timeSpent: '0',
      horizontalPosition: '-1',
      verticalPosition: '-1',
      newInstrumentation: '1',
      ...data,
      journey: ANALYTICS_CONSTANTS.webHomepage,
      eventCategory: ANALYTICS_CONSTANTS.webHomepage,
      platform: isMobile ? ANALYTICS_CONSTANTS.mweb : ANALYTICS_CONSTANTS.dweb,
      prop0: ANALYTICS_CONSTANTS.homepage
   };
   if (id) {
      eventsMap[id] = data;
      const elem = document.getElementById(id);
      elem && observer.observe(elem)
   } else {
      trackEvent(data);
   }
};
