import React, { FC, useEffect, useRef, useState } from 'react';
import {
   Card,
   Image,
   Typography,
   Loader,
   IconButton,
   LeftArrowIcon,
   GridRow,
   GridColumn,
   Button,
   useGetMediaBreakpoint,
   useOrientation,
} from '@airtel-web/legos';
import { ResultCardTypes } from './types/ResultCardTypes';
import { ResultCardConstants } from './ResultCardConstants';
import { navigateTo } from '../../../../../../utils/utils';
import { fireAnalytics } from '../../../../service/HomePageAnalyticsService';
import { ANALYTICS_CONSTANTS } from '../../../../../../common/constants/AnalyticsConstants';
import colors from '@airtel-web/legos/lib/styles/variables/colors.module.scss';
import './ResultCard.scss';

const ResultCard: FC<ResultCardTypes> = (props) => {
   const { conversationalData, currentSlide, moveToPrevious } = props;
   const orientation = useOrientation();
   const mediaBreakpoint = useGetMediaBreakpoint();
   const isMobile = mediaBreakpoint === 'xs' || mediaBreakpoint === 'sm';
   const isMdDevice = mediaBreakpoint === 'md';
   const [loading, setLoading] = useState(true);
   const timeout = useRef<NodeJS.Timeout>();

   useEffect(() => {
      const cardEvents = {
         section: ANALYTICS_CONSTANTS.whatSpeedDoINeed,
         eventAction: ANALYTICS_CONSTANTS.impression,
         eventLabel: 'The best plan for you is ' + conversationalData?.heading?.toLowerCase(),
         eventValue: conversationalData?.heading?.toLowerCase(),
         customValue: conversationalData?.subHeading?.toLowerCase(),
         customLabel: ANALYTICS_CONSTANTS.button,
         isInteractive: '0',
         horizontalPosition: '0',
         verticalPosition: '9',
      };

      const getStartedEvents = {
         section: ANALYTICS_CONSTANTS.whatSpeedDoINeed,
         eventAction: ANALYTICS_CONSTANTS.impression,
         eventLabel: conversationalData?.buttons[0]?.text?.toLowerCase(),
         eventValue: ANALYTICS_CONSTANTS.speed,
         customValue: conversationalData?.subHeading?.toLowerCase(),
         customLabel: ANALYTICS_CONSTANTS.button,
         isInteractive: '0',
         horizontalPosition: '0',
         verticalPosition: '9',
      };
      fireAnalytics(cardEvents, 'resultCard');
      fireAnalytics(getStartedEvents, 'getStartedButton');
   }, [loading,conversationalData]);

   useEffect(() => {
      if ((isMobile && currentSlide === 3) || (!isMobile && currentSlide === 1)) {
         setLoading(true);
         timeout.current = setTimeout(() => {
            setLoading(false);
         }, 500);
      }
      return () => {
         clearTimeout(timeout.current);
      };
   }, [currentSlide, conversationalData]);

   const onGetStartedClick = () => {
      fireAnalytics({
         section: ANALYTICS_CONSTANTS.whatSpeedDoINeed,
         eventAction: ANALYTICS_CONSTANTS.click,
         eventLabel: conversationalData?.buttons[0]?.text?.toLowerCase(),
         eventValue: conversationalData?.heading?.toLowerCase(),
         customValue: conversationalData?.subHeading?.toLowerCase(),
         customLabel: ANALYTICS_CONSTANTS.button,
         isInteractive: '1',
         horizontalPosition: '0',
         verticalPosition: '9',
      });
      if (conversationalData?.buttons[0]?.link)
         navigateTo(conversationalData.buttons[0].link);
   };

   const onAllSpeedsClick = () => {
      fireAnalytics({
         section: ANALYTICS_CONSTANTS.whatSpeedDoINeed,
         eventAction: ANALYTICS_CONSTANTS.click,
         eventLabel: conversationalData?.buttons[1].text?.toLowerCase(),
         customLabel: ANALYTICS_CONSTANTS.button,
         isInteractive: '1',
         horizontalPosition: '0',
         verticalPosition: '9',
      });
      if (conversationalData?.buttons[1]?.link)
         navigateTo(conversationalData.buttons[1].link);
   };

   const getLoader = () => {
      return (
         <Card className="result-card result-card-loader">
            <Typography type="title-para-md" color={colors.TRed500} className="label">
               {conversationalData?.label}
            </Typography>
            <Loader />
            <Typography type="body-para-sm" color={colors.black900}>
               calculating the speed you need!
            </Typography>
            {isMobile && (
               <IconButton
                  size="xs"
                  className="previousSlide"
                  type="primary"
                  icon={LeftArrowIcon}
                  onClick={moveToPrevious}
               />
            )}
         </Card>
      );
   };

   const getResultCard = () => {
      return (
         <Card
            className={
               isMdDevice && orientation === 'landscape'
                  ? 'result-card low-padding'
                  : 'result-card'
            }
            data-testid={ResultCardConstants.resultContainer}
         >
            <GridRow noMargin fullWidth direction="row-as-column" id="resultCard">
               <GridColumn alignSelf="center" noGutter>
                  <Image
                     alt="wifi image"
                     src="home-recast/images/wifi.png"
                     useBaseUrl
                     className="image"
                     width="78"
                     height="78"
                     draggable={false}
                  />
               </GridColumn>
               <GridColumn alignSelf="center" noGutter>
                  <Typography
                     type="body-para-lg-bold"
                     color={colors.black900}
                     className="label"
                  >
                     {conversationalData?.label}
                  </Typography>
               </GridColumn>
               <GridColumn alignSelf="center" noGutter>
                  <Typography
                     type="h4-bold"
                     color={colors.black900}
                     className="heading"
                     data-testid={ResultCardConstants.resultHeading}
                  >
                     {conversationalData?.heading}
                  </Typography>
               </GridColumn>
               <GridColumn alignSelf="center" noGutter>
                  <Typography
                     type="body-single-line-sm"
                     color={colors.black900}
                     className="subHeading"
                  >
                     {conversationalData?.subHeading}
                  </Typography>
               </GridColumn>
               <GridColumn noGutter className="get-started-button-container">
                  <Button
                     id="getStartedButton"
                     data-testid={ResultCardConstants.getStarted}
                     buttonType="primary"
                     version="variable-width"
                     onClick={onGetStartedClick}
                     className="get-started-button"
                  >
                     {conversationalData?.buttons[0].text}
                  </Button>
               </GridColumn>
               <GridColumn noGutter alignSelf="center">
                  <Button
                     data-testid={ResultCardConstants.allSpeeds}
                     buttonType="tertiary"
                     version="variable-width"
                     onClick={onAllSpeedsClick}
                  >
                     {conversationalData?.buttons[1]?.text}
                  </Button>
               </GridColumn>
            </GridRow>
            {isMobile && (
               <IconButton
                  size="xs"
                  className="previousSlide"
                  type="primary"
                  icon={LeftArrowIcon}
                  onClick={moveToPrevious}
               />
            )}
         </Card>
      );
   };

   return loading ? getLoader() : getResultCard();
};

export default ResultCard;
