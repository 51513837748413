export const STATIC_ASSETS_URL = 'https://assets.airtel.in/static-assets/home-recast';
export const CMS_DATA_URL = `${STATIC_ASSETS_URL}/js/cms-data.json`;

export const BASE_HREF_CONSTANTS = {
   default: '/',
};

export const TEST_CONSTANTS = {
   success: 'success',
   empty: 'empty',
   failure: 'failure',
};

/* eslint-disable no-useless-escape */
export const TEST_PATTERNS = {
   mobileNumber: /^[6-9]{1}\d{9}$/,
   dthAndBroadband: /^[3]{1}\d{9}$/,
   numeric: /\D+/g,
   alphaNumeric: /[^A-Za-z0-9\_-]+/g,
   allNumeric: /^\d+$/,
   alphabets: /[^A-Za-z ]$/,
   cookies: '(^| )cookiePermission=([^;]+)',
};

export const STORAGE_CONSTANTS = {
   CMS_DATA: 'cmsData',
   ANNOUNCEMENT_BAR_SHOWN: 'announcementBarShown',
}

export const LOGIN_CONSTANTS = {
   staticToken: 'static-token',
   dynamicToken: 'dynamic-token',
   uid: 'uid',
   loggedInUser: 'aa-auk',
};

export const APP_NAME= 'homepage'

export const CONSUMER_NAME_HEADER = 'AirtelIn';