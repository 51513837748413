import { ANALYTICS_CONSTANTS } from '../../../common/constants/AnalyticsConstants';
import { STORAGE_CONSTANTS } from '../../../common/constants/AppConstants';
import { GENERIC_EXCEPTION } from '../../../common/constants/AppExceptions';
import { fireApiErrorLogSentry } from '../../../common/services/sentry/SentryService';
import { getFromLocalStorage, setInLocalStorage } from '../../../utils/utils';
import {
   CmsDataLayoutTypes,
   CmsDataSectionTypes,
   CmsDataTypes,
} from '../types/CmsDataTypes';
import { fireAnalytics } from './HomePageAnalyticsService';
import { getCmsDataApi, getCmsDataFromAssetsApi } from './HomePageApiService';

const apiRequestData = {
   pageRequests: [
      {
         pageName: 'HomePageNew',
      },
   ],
   appType: 'WEB',
   environment: 'PRODUCTION',
};

let isCmsError = false

/**
 * get home page data from CMS
 * @returns Promise<CmsDataLayoutTypes | null>
 */
export const getCmsData = async (): Promise<CmsDataLayoutTypes | null> => {
   try {
      const response = await getCmsDataApi(apiRequestData);
      if (response) {
         setCmsDataInLocalStorage(response);
         return response?.body[0]?.layouts[0];
      }
      throw GENERIC_EXCEPTION;
   } catch (error:any) {
      fireAnalytics({
         eventAction: ANALYTICS_CONSTANTS.error,
         eventLabel: error?.status || '',
         isInteractive: '0',
         eventValue: error?.data?.errors[0]?.message,
         customValue:error?.config?.url,
         customLabel:ANALYTICS_CONSTANTS.card,
         horizontalPosition: '-1',
         verticalPosition: '-1',
      });
      isCmsError = true
      fireApiErrorLogSentry(
         'HomePageService-->getCmsData-->Unable to get cms data',
         error
      );
      const res = await apiFallback();
      if (res) return res;

      throw error;
   }
};

export const apiFallback = async () => {
   const cmsDataFromLocalStorage = getCmsDataFromLocalStorage();

   if (cmsDataFromLocalStorage?.body[0]?.layouts[0]) {
      return cmsDataFromLocalStorage.body[0].layouts[0];
   }

   const cmsDataFromAssets = await getCmsDataFromAssets();
   if (cmsDataFromAssets?.body[0]?.layouts[0]) {
      return cmsDataFromAssets.body[0].layouts[0];
   }
   return null;
};

/**
 * get cms data for particular section
 * @param {string} sectionName
 * @param {Array<CmsDataSectionTypes>} cmsSectionList
 * @returns {CmsDataSectionTypes | null}
 */
export const getSectionCmsData = (
   sectionName: string,
   cmsSectionList: Array<CmsDataSectionTypes> = []
): CmsDataSectionTypes | null => {
   const sectionData = cmsSectionList?.find(
      (item: any) => item?.sectionName === sectionName
   );
   if (sectionData) return sectionData;

   if (!isCmsError) {
   fireApiErrorLogSentry(
      `HomePageService-->getSectionCmsData-->Unable to get ${sectionName} data`,
      sectionData
   );
   }
   return null;
};

/**
 * get cms data from assets
 * @returns {Promise<CmsDataTypes | null}
 */
export const getCmsDataFromAssets = async (): Promise<CmsDataTypes | null> => {
   try {
      const response = await getCmsDataFromAssetsApi();
      if (response) {
         setCmsDataInLocalStorage(response);
      }
      return response;
   } catch (error) {
      fireApiErrorLogSentry(
         'HomePageService-->getCmsDataFromAssets-->Unable to get data from assets',
         error
      );
      return null;
   }
};

/**
 * to set cms data in local storage
 * @param {CmsDataTypes} data
 */
export const setCmsDataInLocalStorage = (data: CmsDataTypes) => {
   try {
      const _data = btoa(encodeURIComponent(JSON.stringify(data)));
      setInLocalStorage(STORAGE_CONSTANTS.CMS_DATA, _data);
   } catch (error) {
      fireApiErrorLogSentry(
         'HomePageService-->setCmsDataInLocalStorage-->Unable to set cms data in local storage',
         error
      );
   }
};

/**
 * to get the cms data from storage
 * @returns {CmsDataTypes | null}
 */
export const getCmsDataFromLocalStorage = (): CmsDataTypes | null => {
   try {
      const data = getFromLocalStorage(STORAGE_CONSTANTS.CMS_DATA);
      return data ? JSON.parse(decodeURIComponent(atob(data))) : null;
   } catch (error) {
      fireApiErrorLogSentry(
         'HomePageService-->getCmsDataFromLocalStorage-->Unable to get cms data from local storage',
         error
      );
      return null;
   }
};
