export const B2C_Data = [
    {
        ctaLink: 'https://www.airtel.in/prepaid-recharge?icid=404page',
        imageLink: 'https://assets.airtel.in/static-assets/selfcare/images/404/prepaid@3x.png',
        title: 'Mobile Recharge'
    },
    {
        ctaLink: 'https://www.airtel.in/dth-recharge?icid=404page',
        imageLink: 'https://assets.airtel.in/static-assets/selfcare/images/404/dth-recharge@3x.png',
        title: 'DTH Recharge'
    },
    {
        ctaLink: 'https://www.airtel.in/postpaid-bill-pay/?icid=404page',
        imageLink: 'https://assets.airtel.in/static-assets/selfcare/images/404/postpaid@3x.png',
        title: 'Postpaid Recharge'
    },
    {
        ctaLink: 'https://www.airtel.in/myplan-infinity/?icid=404page',
        imageLink: 'https://assets.airtel.in/static-assets/selfcare/images/404/buy-postpaid@3x.png',
        title: 'Buy Postpaid'
    },
    {
        ctaLink: 'https://www.airtel.in/dth/?icid=404page',
        imageLink: 'https://assets.airtel.in/static-assets/selfcare/images/404/buy-dth@3x.png',
        title: 'Buy DTH'
    },
    {
        ctaLink: 'https://www.airtel.in/broadband/?icid=404page',
        imageLink: 'https://assets.airtel.in/static-assets/selfcare/images/404/buy-broadband@3x.png',
        title: 'Buy Broadband'
    },
];

export const B2B_Data = [
    {
        ctaLink: 'https://www.airtel.in/business/b2b/broadband-internet/?icid=404page&amp;=AOI&amp;utm_medium=airtel_web&amp;utm_source=404',
        imageLink: 'https://assets.airtel.in/static-assets/misc/img/b2b-airtel-office-internet.svg',
        title: 'Airtel Office Internet'
    },
    {
        ctaLink: 'https://www.airtel.in/business/b2b/internet-leased-line?icid=404page&amp;utm_campaign=ILL&amp;utm_medium=airtel_web&amp;utm_source=404',
        imageLink: 'https://assets.airtel.in/static-assets/misc/img/b2b-airtel-dedicated-internet.svg',
        title: 'Dedicated Internet'
    },
    {
        ctaLink: 'https://www.airtel.in/business/b2b/corporate-postpaid?icid=404page&amp;utm_campaign=COCP&amp;utm_medium=airtel_web&amp;utm_source=404',
        imageLink: 'https://assets.airtel.in/static-assets/misc/img/b2b-mobile.svg',
        title: 'Corporate Postpaid'
    },
    {
        ctaLink: 'https://www.airtel.in/business/b2b/airtel-iot?icid=404page&amp;utm_campaign=IOT&amp;utm_medium=airtel_web&amp;utm_source=404',
        imageLink: 'https://assets.airtel.in/static-assets/misc/img/b2b-iot.svg',
        title: 'Airtel IOT'
    },
    {
        ctaLink: 'https://www.airtel.in/business/b2b/airtel-iq?icid=404page&amp;utm_campaign=IQ&amp;utm_medium=airtel_web&amp;utm_source=404',
        imageLink: 'https://assets.airtel.in/static-assets/misc/img/b2b-airtel-iq.svg',
        title: 'Airtel IQ'
    },
    {
        ctaLink: 'https://www.airtel.in/business/b2b/airtel-landline?icid=404page&amp;utm_campaign=Landline&amp;utm_medium=airtel_web&amp;utm_source=404',
        imageLink: 'https://assets.airtel.in/static-assets/misc/img/b2b-landline.svg',
        title: 'Airtel Landline'
    },
]