export const B2B_SLIDER_AUTOPLAY_DELAY = 5000;

export const B2B_SLIDER_BREAKPOINTS = {
   xs: {
      gutter: 16,
      slidesToShow: 1,
      slidesToScroll: 1,
      seekGap: 12,
   },
   sm: {
      slidesToScroll: 1,
      gutter: 16,
      slidesToShow: 1,
      seekGap: 12,
   },
   md: {
      slidesToScroll: 1,
      gutter: 10,
      slidesToShow: 3,
      seekGap: 4,
   },
   lg: {
      slidesToScroll: 1,
      gutter: 37,
      slidesToShow: 4,
      seekGap: 0,
   },
};

export const B2B_SECTION_TEST_ID = {
   slider: 'slider',
   prevSlideButton: 'prevSlideButton',
   nextSlideButton: 'nextSlideButton',
   slide: 'slide',
   currentBannerDiv: 'currentBannerDiv',
};
