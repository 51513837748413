import React, { FC, useEffect } from 'react';
import {
   Image,
   RightArrowIcon,
   Typography,
} from '@airtel-web/legos';
import { navigateTo } from '../../../../../../utils/utils';
import { B2BSlidePropTypes } from './types/B2BSlidePropTypes';
import { STATIC_ASSETS_URL } from '../../../../../../common/constants/AppConstants';
import { fireAnalytics } from '../../../../service/HomePageAnalyticsService';
import { ANALYTICS_CONSTANTS } from '../../../../../../common/constants/AnalyticsConstants';
import colors from '@airtel-web/legos/lib/styles/variables/colors.module.scss';
import './B2BSlide.scss';

const B2BSlide: FC<B2BSlidePropTypes> = (props) => {
   const { attributes, index } = props;
   const { heading, subHeading, iconUrl, cardCtaLink } = attributes;

   const cardEvents = {
      section: ANALYTICS_CONSTANTS.forYourBusiness,
      eventAction: ANALYTICS_CONSTANTS.impression,
      eventLabel: heading?.toLowerCase(),
      eventValue: subHeading?.toLowerCase(),
      customLabel: ANALYTICS_CONSTANTS.button,
      isInteractive: '0',
      horizontalPosition: index.toString(),
      verticalPosition: '8',
   };

   useEffect(() => {
      fireAnalytics(cardEvents, `b2b-slide-container-${index}`);
   }, []);

   const onCardClick = () => {
      fireAnalytics({
         ...cardEvents,
         eventAction: ANALYTICS_CONSTANTS.click,
         isInteractive: '1',
      });
      navigateTo(cardCtaLink);
   };

   return (
      <div
         className="b2b-slide-container"
         id={`b2b-slide-container-${index}`}
         onClick={onCardClick}
      >
         <div className="icon-container">
            <Image
               alt="b2b card icon"
               src={iconUrl || `${STATIC_ASSETS_URL}/images/b2b-slide-1.svg`}
               height="40"
               width="40"
               className="b2b-icon"
               useBaseUrl={false}
               draggable={false}
            />
         </div>
         <div className="b2b-slide-content">
            {heading && (
               <div className='slide-heading-container'>
                  <Typography
                     type='body-para-md-bold'
                     className="slide-heading"
                     color={colors.black900}
                  >
                     {heading}
                  </Typography>
                  <RightArrowIcon className="b2b-slide-arrow" color={colors.blue500} />
               </div>
            )}

            {subHeading && (
               <Typography
                  type='body-para-xs'
                  className="slide-subHeading"
                  color={colors.black700}
               >
                  {subHeading}
               </Typography>
            )}
         </div>
      </div>
   );
};

export default B2BSlide;
